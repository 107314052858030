import React, {Fragment, useCallback, useMemo, useState} from "react";
import {Datepicker} from "flowbite-react";
import {Button, Dialog, DialogPanel, DialogTitle, Field, Label, Switch} from '@headlessui/react';
import {CalendarDateRangeIcon, XMarkIcon} from '@heroicons/react/20/solid'
import useToggle from "../../../hooks/UseToggle";
import {DateFormatter} from "../../../utils/Datetime";
import {useDidUpdateEffect} from "../../../hooks/UseDidUpdateEffect";

export const customTheme = {
    "root": {
        "base": "relative"
    },
    "popup": {
        "root": {
            "base": "absolute top-10 z-50 block pt-2",
            "inline": "relative top-0 z-auto",
            "inner": "inline-block rounded-lg bg-white p-4 shadow-lg dark:bg-gray-700"
        },
        "header": {
            "base": "",
            "title": "px-2 py-3 text-center font-semibold text-gray-900 dark:text-white",
            "selectors": {
                "base": "mb-2 flex justify-between",
                "button": {
                    "base": "rounded-lg bg-white px-5 py-2.5 text-sm font-semibold text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600",
                    "prev": "",
                    "next": "",
                    "view": ""
                }
            }
        },
        "view": {
            "base": "p-1"
        },
        "footer": {
            "base": "mt-2 flex space-x-2",
            "button": {
                "base": "w-full rounded-lg px-5 py-2 text-center text-sm font-medium focus:ring-4 focus:ring-blue-300",
                "today": "bg-blue-700 text-white hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700",
                "clear": "border border-gray-300 bg-white text-gray-900 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600"
            }
        }
    },
    "views": {
        "days": {
            "header": {
                "base": "mb-1 grid grid-cols-7",
                "title": "h-6 text-center text-sm font-medium leading-6 text-gray-500 dark:text-gray-400"
            },
            "items": {
                "base": "grid w-64 grid-cols-7",
                "item": {
                    "base": "block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600",
                    "selected": "bg-blue-700 text-white hover:bg-blue-600",
                    "disabled": "text-gray-500"
                }
            }
        },
        "months": {
            "items": {
                "base": "grid w-64 grid-cols-4",
                "item": {
                    "base": "block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600",
                    "selected": "bg-blue-700 text-white hover:bg-blue-600",
                    "disabled": "text-gray-500"
                }
            }
        },
        "years": {
            "items": {
                "base": "grid w-64 grid-cols-4",
                "item": {
                    "base": "block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600",
                    "selected": "bg-blue-700 text-white hover:bg-blue-600",
                    "disabled": "text-gray-500"
                }
            }
        },
        "decades": {
            "items": {
                "base": "grid w-64 grid-cols-4",
                "item": {
                    "base": "block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9 text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600",
                    "selected": "bg-blue-700 text-white hover:bg-blue-600",
                    "disabled": "text-gray-500"
                }
            }
        }
    }
}

/*
 * <Button
 *                 className={`bg-grey-200 font-medium py-1.5 px-3 rounded-md border-2 border-gray-300 no-underline text-sm hover:bg-gray-50 focus:outline-none transition-all duration-200 flex gap-2 items-center ring-gray-300 ring-offset-1 hover:ring
 *                             ${isOpen ? 'text-purple-800' : 'text-gray-900'}
 *                             ${isOpen ? 'border-purple-800 hover:ring-0 bg-gray-50' : 'border-gray-300'}
 *                             `}
 *                 onClick={toggle}
 *             >
 *                 Dátumový rozsah
 *                 <CalendarDateRangeIcon className={`w-4 h-4
 *                             ${isOpen ? 'text-purple-800' : 'text-gray-500'}`}/>
 *             </Button>
 */


const DateRange = ({onChange, optional = true}) => {
    const {state: isOpen, toggle} = useToggle();
    const [enabled, setEnabled] = useState(!optional);

    const [startDate, setStartDate] = useState(() => {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth(), 1);
    });
    const [endDate, setEndDate] = useState(() => {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth() + 1, 0);
    });

    const handleStartDateChange = useCallback((date) => {
        setStartDate(date);
    }, [setStartDate]);

    const handleEndDateChange = useCallback((date) => {
        setEndDate(date);
    }, [setEndDate]);

    useDidUpdateEffect(() => {
        onChange(enabled ? [startDate, endDate] : null);
    }, [enabled, endDate, startDate]);

    const formattedDates = useMemo(() => {
        return DateFormatter.fromDate(startDate) + ' – ' + DateFormatter.fromDate(endDate);
    }, [startDate, endDate]);

    return (
        <>
            <Button
                className={`relative min-w-52 cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left border border-gray-300 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-300 text-sm text-gray-500`}
                onClick={toggle}
            >
                <div className={(!optional || enabled) ? 'text-gray-900' : 'text-gray-500'}>{(!optional || enabled) ? formattedDates : 'Dátumový rozsah'}</div>
                <div className={"pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"}><CalendarDateRangeIcon className={`w-5 h-5`} aria-hidden="true"/></div>
            </Button>
            <Dialog open={isOpen} onClose={toggle} className="relative z-50">
                <div className="fixed inset-0 flex w-screen items-center justify-center p-4 bg-black bg-opacity-35 backdrop-blur-sm">
                    <DialogPanel className="max-w-lg bg-white shadow-lg rounded-2xl mb-24">
                        <DialogTitle className="font-bold border-b bg-gradient-to-b from-white to-gray-50 p-3 rounded-t-2xl flex justify-between items-center">
                            Dátumový rozsah
                            <XMarkIcon className="w-5 h-5 text-gray-500 cursor-pointer hover:text-gray-400 transition-colors" onClick={toggle}/>
                        </DialogTitle>
                        <div className={"p-5"}>
                            {optional &&  <Field className="mb-4 flex justify-between items-center gap-5">
                                <Label>Nastaviť rozsah</Label>
                                <Switch
                                    checked={enabled}
                                    onChange={setEnabled}
                                    className="group inline-flex h-6 w-11 items-center rounded-full bg-gray-200 transition data-[checked]:bg-blue-600"
                                >
                                    <span className="size-4 translate-x-1 rounded-full bg-white transition group-data-[checked]:translate-x-6" />
                                </Switch>
                            </Field> }
                            <div
                                className={`${enabled ? 'pointer-events-auto' : 'opacity-50 pointer-events-none'} flex flex-col gap-4`}>
                                <div className={"flex gap-2 items-center flex-col sm:flex-row"}>
                                    <Datepicker
                                        language="sk-SK"
                                        weekStart={1}
                                        theme={customTheme}
                                        labelTodayButton={"Dnes"}
                                        labelClearButton={"Zrušiť"}
                                        defaultDate={startDate}
                                        onSelectedDateChanged={handleStartDateChange}
                                    />
                                    <div className={"text-gray-400 text-sm"}>do</div>
                                    <Datepicker
                                        language="sk-SK"
                                        weekStart={1}
                                        theme={customTheme}
                                        labelTodayButton={"Dnes"}
                                        labelClearButton={"Zrušiť"}
                                        defaultDate={endDate}
                                        onSelectedDateChanged={handleEndDateChange}
                                    />
                                </div>
                            </div>
                        </div>


                    </DialogPanel>
                </div>
            </Dialog>
        </>
    )
}

export default DateRange;