import {Fragment, useState} from 'react'
import {Listbox, Transition} from '@headlessui/react'
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/react/20/solid'
import Badge from "../../Badge";
import {getSourceColor, getSourceText} from "../../../utils/FormatingUtils";
import {useDidUpdateEffect} from "../../../hooks/UseDidUpdateEffect";

const statuses = [
    'direct', 'eshop', 'pharmacy', 'distributor'
]

const ListboxSource = ({onChange, defaultValue = null, hideNullOption = false}) => {
    const [selected, setSelected] = useState(() => defaultValue);

    useDidUpdateEffect(() => {
        onChange(selected)
    }, [selected]);

    return (
        <div className="w-40">
            <Listbox value={selected} onChange={setSelected}>
                <div className="relative">
                    <Listbox.Button
                        className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left border border-gray-300 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-300 text-sm">
                        <span className="block truncate">
                        {selected ? getSourceText(selected) : <span className={"text-gray-500"}>Kategória</span>}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                          />
                        </span>
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options
                            className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm z-10">

                            {!hideNullOption && <>
                            <Listbox.Option
                                className={({active}) =>
                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                        active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                    }`
                                }
                                value={null}
                            >
                                {({selected, active}) => (
                                    <>
                                      <span
                                          className={`block truncate text-sm ${
                                              selected ? 'font-medium' : 'font-normal'
                                          }`}
                                      >
                                           Všetky
                                      </span>
                                        {selected ? (
                                            <span
                                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                                    active ? 'text-white' : 'text-indigo-600'
                                                }`}
                                            >
                                              <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                            </span>
                                        ) : null}
                                    </>
                                )}
                            </Listbox.Option>
                            </>}

                            {statuses.map((status) => (
                                <Listbox.Option
                                    key={status}
                                    className={({active}) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                            active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                        }`
                                    }
                                    value={status}
                                >
                                    {({selected, active}) => (
                                        <>
                                          <span
                                              className={`block truncate ${
                                                  selected ? 'font-medium' : 'font-normal'
                                              }`}
                                          >
                                               <Badge color={getSourceColor(status)}>{getSourceText(status)}</Badge>
                                          </span>
                                            {selected ? (
                                                <span
                                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                                        active ? 'text-white' : 'text-indigo-600'
                                                    }`}
                                                >
                                                  <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        </div>
    )
}

export default ListboxSource;