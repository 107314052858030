import React, {useState} from 'react';
import {Transition} from "@headlessui/react";
import {PlusIcon} from "@heroicons/react/20/solid";

const HeaderButton = ({label, onClick = undefined}) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div
            className="inline-flex items-center"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <button
                className={`flex items-center gap-2 text-sm bg-grey-200 font-medium p-2 rounded-full border-2 border-gray-300 hover:bg-gray-50 focus:outline-none transition-all duration-300 ring-gray-300 ring-offset-1 hover:ring text-gray-900 overflow-hidden ${isHovered ? 'pr-3' : ''}`}
                onClick={onClick}
            >
                <PlusIcon className="w-5 h-5 flex-shrink-0" />
                <Transition
                    show={isHovered}
                    enter="transition-all duration-150 ease-in-out"
                    enterFrom="opacity-0 max-w-0"
                    enterTo="opacity-100 max-w-xs"
                    leave="transition-all duration-150 ease-in-out"
                    leaveFrom="opacity-100 max-w-xs"
                    leaveTo="opacity-0 max-w-0"
                >
                    <span className="whitespace-nowrap">
                        {label}
                    </span>
                </Transition>
            </button>
        </div>
    );
}

export default HeaderButton;