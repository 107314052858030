import Dashboard from "../../layouts/Dashboard";
import useIndex from "../../hooks/UseIndex";
import {API_URL} from "../../config";
import {LeadType} from "../../types/global";
import LeadTableRow from "./compoments/LeadTableRow";
import Pagination from "../../components/Pagination";
import SearchBar from "../../components/Dashboard/SearchBar";
import React, {useMemo, useState} from "react";
import FiltersContainer from "../../components/Dashboard/FiltersContainer";
import useRefreshButtonNum from "../../hooks/Dashboard/UseRefreshButtonNum";
import useFilters from "../../hooks/Dashboard/UseFilters";
import {Status} from "../../types/enums";
import useEffectOnChange from "../../hooks/UseEffectOnValueChange";
import RefreshButton from "../../components/Dashboard/RefreshButton";
import FilterButton from "../../components/Dashboard/FilterButton";
import {Spinner} from "flowbite-react";
import ComboboxRep from "../../components/Filters/ComboboxRep";
import ListboxSource from "../../components/Filters/ListboxSource";
import {useModelEvent} from "../../hooks/UseModelEvents";
import HeaderButton from "../../components/Dashboard/HeaderButton";
import {useNavigate} from "react-router-dom";

const FiltersComponents = [
    {id: "rep", Component: ComboboxRep},
    {id: "source", Component: ListboxSource},
]

const LeadIndex = () => {

    const navigate = useNavigate();

    const limit = useMemo(() => 10, []);
    const endpoint = useMemo(() => `${API_URL}/lead`, []);

    const {data, status, fetchData, meta, setPage} = useIndex<LeadType>(endpoint, limit);
    const [search, setSearch] = useState("");

    const isPending = useMemo(() => status === Status.Pending || status === Status.NotLoaded, [status]);

    const {refreshButtonNum, incrementRefreshButtonNum} = useRefreshButtonNum();
    const {isFilterActive, toggleFilters, filters, activeFilters, handleFilterChange} = useFilters();

    useEffectOnChange(() => {
        const params = {page: 1}
        if (search) params['search'] = search;

        // Build filters
        if (isFilterActive) {
            filters.forEach((filter) => {
                if (filter.value) {
                    switch (filter.id) {
                        case 'rep':
                            params['rep_id'] = filter.value._id;
                            break;
                        case 'source':
                            params['source'] = filter.value;
                            break;
                        default:
                            break;
                    }
                }
            })
        }

        fetchData(params);
    }, [filters, search, refreshButtonNum]);

    const eventsToListen = useMemo(() => ["OnCreate", "OnDelete", "OnUpdate"], []);
    useModelEvent('Lead', eventsToListen, incrementRefreshButtonNum);

    const headerButton = useMemo(() => <HeaderButton label={"Nový lead"} onClick={() => navigate('/lead/create')} />, [navigate]);

    return (<Dashboard heading={"Leady"} headerButton={headerButton}>

        <div className={"flex justify-between items-center gap-4"}>
            <SearchBar onChange={setSearch}/>

            <div className={"flex gap-3"}>
                <RefreshButton isPending={isPending} onClick={incrementRefreshButtonNum}/>
                <FilterButton onClick={toggleFilters} isActive={isFilterActive} activeFilters={activeFilters}/>
            </div>
        </div>

        <FiltersContainer onChange={handleFilterChange} show={isFilterActive} filters={FiltersComponents}/>

        {data && <>
                <div className="overflow-x-auto w-full">
                    <table className="w-full mt-5 min-w-[600px]">
                        <thead className="text-sm sm:text-md text-left bg-gray-200 uppercase text-gray-500">
                        <tr className={"rounded-lg"}>
                            <th className={"font-normal py-1.5 px-2"}>Meno</th>
                            <th className={"font-normal py-1.5 px-2"}>Spoločnosť</th>
                            <th className={"font-normal py-1.5 px-2"}>Zástupca</th>
                            <th className={"font-normal py-1.5 px-2"}>Kategória</th>
                            <th className={"font-normal py-1.5 px-2"}></th>
                        </tr>
                        </thead>
                        <tbody className={"text-xs sm:text-sm"}>
                            {
                            data.length === 0 ?
                                <>
                                    {
                                        isPending ?
                                            <tr>
                                                <td colSpan={5} className={"text-center py-6"}><Spinner
                                                    size="lg"
                                                    className={"mx-auto"}/>
                                                </td>
                                            </tr> :
                                            <tr>
                                                <td colSpan={5} className={"text-center text-gray-500 py-4"}>Žiadne
                                                    výsledky
                                                </td>
                                            </tr>
                                    }
                                </> :
                                data.map((lead) => (
                                    <LeadTableRow key={lead._id} lead={lead}/>
                                ))
                        }
                        </tbody>
                    </table>
                </div>
                <Pagination meta={meta} setPage={setPage}/>
            </>
        }

    </Dashboard>);
}

export default LeadIndex;