import {ResourceType} from "../types/global";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Status} from "../types/enums";
import {isEqual} from "lodash";
import {useAuth} from "../contexts/AuthContext";


const CONFIG_DEFAULTS = {
    deleteAlertMessage: 'Vymazať?',
    duration: 1000,
    initialState: null
}

const useResource = <T extends unknown>(endpoint: string, conf?): ResourceType<T> => {

    const config = useMemo(() => ({...CONFIG_DEFAULTS, ...conf}), [conf]);

    const [data, setData] = useState<T | null>(() => config.initialState);
    const [storedData, setStoredData] = useState<T | null>(null);

    const [etag, setEtag] = useState<string | null>(null);

    const [fetchStatus, setFetchStatus] = useState(Status.NotLoaded);
    const [updateStatus, setUpdateStatus] = useState(Status.Idle);
    const [deleteStatus, setDeleteStatus] = useState(Status.Idle);

    const abortController = useRef(new AbortController());

    const {authToken, logout} = useAuth();

    // Normalise strings
    useEffect(() => {
        if (data) {
            let normalisedData: any = {};
            Object.keys(data).forEach(key => {
                if (typeof data[key] === 'string') {
                    const trimmed = data[key].trim();
                    if (data[key] !== trimmed) {
                        normalisedData[key] = trimmed;
                    }
                }
            });
            if (Object.keys(normalisedData).length > 0) {
                // @ts-ignore
                setData((prevData) => ({...prevData, ...normalisedData}));
            }
        }
    }, [data]);

    const fetchResource = useCallback(async (urlParams = {}) => {
        if (updateStatus === Status.Pending || deleteStatus === Status.Pending) return;

        // Dont fetch if data upload is pending
        // console.log('here');
        if (data && storedData) {
            const updatedValues = Object.keys(data).filter(key => !isEqual(data[key], storedData[key]));
            if (updatedValues.length > 0) return;
        }

        // console.log('fetching data', endpoint);
        abortController.current.abort();
        abortController.current = new AbortController();

        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        };

        if (etag) headers['If-None-Match'] = `${etag}`;

        const params = new URLSearchParams(urlParams);

        setFetchStatus(Status.Pending);
        try {
            const response = await fetch(
                `${endpoint}?${params.toString()}`,
                {
                    method: 'GET',
                    headers,
                    signal: abortController.current.signal
                }
            );

            if (response.ok) {
                // check if response is not modified
                if (response.status === 304 || (response.headers.get('etag') && response.headers.get('etag') === etag)) {
                    setFetchStatus(Status.Complete);
                    return;
                }

                // update etag
                if (response.headers.get('etag')) setEtag(response.headers.get('etag'));

                // parse response
                const data = await response.json();
                setData(data);
                setStoredData(data);
                setFetchStatus(Status.Complete);
            } else {
                if (response.status === 401) {
                    logout();
                }
                setFetchStatus(Status.Failed);
                console.error(response.status, response.statusText)
            }

            // setCache(response.data);

        } catch (e) {
            if (e && e.name === 'AbortError') {
                setFetchStatus(Status.Complete);
                console.log('Fetch aborted');
                return;
            }

            console.error(e);
            setFetchStatus(Status.Failed);
        }
    }, [updateStatus, deleteStatus, data, storedData, endpoint, authToken, etag, logout]);

    const deleteResource = useCallback(async () => {
        setDeleteStatus(Status.Pending);
        try {

            const response = await fetch(endpoint, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                }
            });

            if (!response.ok) {
                console.error(response.status, response.statusText);
                throw new Error('Failed to delete resource');
            }

            // Haptics.notificationAsync(Haptics.NotificationFeedbackType.Warning);
            setDeleteStatus(Status.Complete);
            setStoredData(null);
            config.onDelete && config.onDelete();
        } catch (e) {
            console.error(e);
            alert('Akciu sa nepodarilo dokončiť.');
            setDeleteStatus(Status.Failed);
        }
    }, [authToken, endpoint, config]);

    const updateResource = useCallback(async (specifiedPayload?: T) => {
        let payload = null;

        if (specifiedPayload) {
            // @ts-ignore
            setData((prevData) => ({...prevData, ...specifiedPayload}));
            payload = specifiedPayload;
        } else {
            if (data === null || storedData === null) return;
            const updatedValues = Object.keys(data).filter(key => !isEqual(data[key], storedData[key]));
            if (updatedValues.length === 0) return;
            console.log('updatedValues', updatedValues);

            payload = updatedValues.reduce((acc, key) => {
                acc[key] = data[key];
                return acc;
            }, {});
        }

        abortController.current.abort();
        setUpdateStatus(Status.Pending);

        console.log('uploading...', payload);
        const response = await fetch(endpoint, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            },
            body: JSON.stringify(payload)
        });


        if (response.ok) {
            const data = await response.json();
            setData(data)
            setStoredData(data);
            setUpdateStatus(Status.Complete);
            if (response.headers.get('etag')) setEtag(response.headers.get('etag'));
            // setCache(response.data);
            return {
                success: true,
            }
        } else {
            setData(storedData);
            setUpdateStatus(Status.Failed);
            return {
                success: false,
                status: response.status,
                statusText: response.statusText
            }
        }

    }, [endpoint, authToken, data, storedData]);

    useEffect(() => {
        const timeout = setTimeout(updateResource, config.duration);
        return () => clearTimeout(timeout);
    }, [updateResource, config.duration]);

    const isPending = useMemo(() => {
        return fetchStatus === Status.Pending || updateStatus === Status.Pending || deleteStatus === Status.Pending;
    }, [fetchStatus, updateStatus, deleteStatus]);

    const resourceEmpty = useMemo(() => {
        return data === null && (fetchStatus === Status.Complete || fetchStatus === Status.Failed);
    }, [data, fetchStatus]);

    const deleteAlert = useCallback(() => {
        if (window.confirm(config.deleteAlertMessage)) {
            deleteResource();
        }
    }, [config.deleteAlertMessage, deleteResource]);

    useEffect(() => {
        fetchResource();
    }, []);

    const forceSetData = useCallback((data: T) => {
        setData(data);
        setStoredData(data);
    }, []);

    return {
        data,
        setData,
        forceSetData,
        isPending,
        resourceEmpty,
        fetchResource,
        deleteStatus,
        updateResource,
        deleteAlert
    }
}

export default useResource;