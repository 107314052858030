import {useCallback, useMemo, useState} from "react";
import {useDidUpdateEffect} from "../UseDidUpdateEffect";
import useToggle from "../UseToggle";

const useFilters = () => {
    const {state: isFilterActive, toggle: toggleFilters} = useToggle();

    const [filters, setFilters] = useState([]);
    const activeFilters = useMemo(() => isFilterActive ? filters.filter((f) => f.value).length : 0, [filters, isFilterActive]);

    useDidUpdateEffect(() => {
        if (!isFilterActive) {
            setFilters([]);
        }
    }, [isFilterActive]);

    const handleFilterChange = useCallback((id, value) => {
        setFilters((prev) => {
            const index = prev.findIndex((filter) => filter.id === id);
            if (index === -1) {
                return [...prev, {id, value}]
            }
            return prev.map((filter) => filter.id === id ? {id, value} : filter)
        })
    }, []);

    return {isFilterActive, toggleFilters, filters, activeFilters, handleFilterChange};
}

export default useFilters;