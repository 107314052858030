export class DateFormatter {
    static fromString(date_str: string): string {
        // Parse the input date string
        const date = new Date(date_str);
        return this.fromDate(date);
    }

    static fromDate(date: Date) {
        // Array of Slovak month names
        const monthNames = [
            "januára", "februára", "marca", "apríla", "mája", "júna",
            "júla", "augusta", "septembra", "októbra", "novembra", "decembra"
        ];

        // Get day, month, and year
        const day = date.getDate(); // No padding needed
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();

        // Construct the final formatted string
        return `${day}. ${month} ${year}`;
    }

    static convertToISO8601(date: Date): string {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }
}