import React, {useCallback} from 'react';
import styles from './style.module.scss';
import {ReplyToButtonProps} from './index.d';
import {useDispatch} from "react-redux";
import {setReplyTo} from "../../slices/ReplyToSlice";
import {ReactComponent as ReplyIcon} from "../../icons/reply.svg";

const ReplyToButton: React.FC<ReplyToButtonProps> = ({displayAs, sendAs}) => {

    // const replyToState = useSelector(state => state.replyTo);
    const dispatch = useDispatch();

    const onClick = useCallback(() => {
        dispatch(setReplyTo({displayAs, sendAs: (sendAs ?? displayAs)}));
    }, [dispatch, displayAs, sendAs]);

    return <div role={"button"} className={styles.container} onClick={onClick}>
        <ReplyIcon />
    </div>;
}

export default ReplyToButton;