import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styles from './style.module.scss';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useAuth} from "../../contexts/AuthContext";
import AuthLayout from "../../layouts/AuthLayout";
import {API_URL} from "../../config";
import LoadingScreen from "../../components/LoadingScreen";


const Invitation = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const token = useMemo(() => new URLSearchParams(location.search).get('token'), [location.search]);
    const [isPending, setPending] = useState<boolean>(false);
    const emailRef = useRef(null);

    // const [disabled, setDisabled] = useState({email: false});
    const [prefill, setPrefill] = useState<any>({});

    const [isInvalid, setIsInvalid] = useState<any>({
        email: false,
    });
    const [status, setStatus] = useState<'pending' | 'already_accepted' | 'not_found' | 'valid' | 'error'>('pending');

    const resetInvalid = useCallback(() => {
        setIsInvalid((prev) => {
            Object.keys(prev).forEach((key) => {
                prev[key] = false;
            });
            return {...prev};
        });
    }, []);

    const setInvalid = useCallback((key) => {
        setIsInvalid((prev) => {
            prev[key] = true;
            return {...prev};
        });
    }, []);

    const {login, isAuthenticated} = useAuth();

    /** Navigate out if invitation token is not present */
    useEffect(() => {
        if (!token) navigate('/');
    }, [navigate, token]);

    useEffect(() => {
        if (isAuthenticated) navigate('/chat');
    }, [isAuthenticated, navigate]);

    useEffect(() => {
        const verify = async () => {
            const response = await fetch(`${API_URL}/invitation/${token}/verify`, {
                method: 'GET',
                headers: {'Content-Type': 'application/json',},
            });

            if (response.ok) {
                const res = await response.json();
                if (res.status) setStatus(res.status)
                if (res.prefill) setPrefill(res.prefill);
            } else {
                setStatus('error')
            }
        }

        verify();
    }, []);

    const submit = useCallback(async (event?) => {
        event?.preventDefault();
        if  (isPending) {
            return;
        }

        setPending(true);
        resetInvalid()

        const response = await fetch(`${API_URL}/invitation/${token}`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json',},
            body: JSON.stringify({ email: emailRef.current.value }),
        });

        if (response.ok) {
            login(await response.json());
        } else {
            if (response.status === 422) {
                const resData = await response.json();
                resData.data?.loc?.forEach((loc) => {
                    setInvalid(loc);
                });
            }
        }

        setPending(false);
    }, [isPending, login, resetInvalid, setInvalid, token]);

    const Component = useMemo(() => {
        switch (status) {
            case 'pending':
                return <LoadingScreen wrapperStyle={{height: '100%'}} />;
            case 'already_accepted':
                return <div className={styles.container}>
                    <h1 className={styles.title}>Pozvánka už bola prijatá</h1>
                    <div className={styles.lead}>Pozvánka už bola prijatá, môžete sa prihlásiť.</div>
                    <Link to={'/'} className={"button autoSize"}>Prihlásiť sa</Link>
                </div>;
            case 'not_found':
                return <div className={styles.container}>
                    <h1 className={styles.title}>Pozvánka neexistuje</h1>
                    <div className={styles.lead}>Pozvánka neexistuje alebo je neplatná.</div>
                    <Link to={'/'} className={"button autoSize"}>Domov</Link>
                </div>;
            case "error":
                return <div className={styles.container}>
                    <h1 className={styles.title}>Chyba</h1>
                    <div className={styles.lead}>Nastala chyba pri overovaní pozvánky.</div>
                    <Link to={'/'} className={"button autoSize"}>Domov</Link>
                </div>;
            case 'valid':
                return <form className={styles.container} onSubmit={submit}>
                    <h1 className={styles.title}>Vytvorte si prihlasovacie údaje</h1>
                    <div className={styles.lead}>Zadajte email s ktorým sa chcete prihlasovať.</div>
                    <div className={styles.inputContainer}>
                        <input className={styles.formInput}
                               type={"email"}
                               placeholder={"Email"}
                               name={"email"}
                               autoComplete={"email"}
                               ref={emailRef}
                               required={true}
                               onClick={resetInvalid}
                               aria-invalid={isInvalid.email}
                               disabled={prefill.email}
                               value={prefill.email ? prefill.email : undefined}
                        />
                    </div>
                    <button className={`button ${styles.button}`} type={"submit"} disabled={isPending}>Pokračovať</button>
                </form>;
        }
    }, [status, submit, resetInvalid, isInvalid.email, prefill, isPending]);

    return (
        <AuthLayout>
            {Component}
        </AuthLayout>
    );
}

export default Invitation;
