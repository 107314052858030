import React, {createContext, useCallback, useContext, useLayoutEffect, useMemo, useState} from 'react';
import {AuthResponse} from "./AuthContext.d";
import Cookies from 'js-cookie';
import {UserType} from "../types/global";
import {API_URL} from "../config";

type ExchangeType = { userSecret: string, email: string };

type AuthContextType = {
    isAuthenticated: boolean;
    authToken: string;
    login: (res: AuthResponse) => void;
    logout: () => void;
    isPending: boolean;
    exchange: ExchangeType;
    setExchange: (exchange: ExchangeType) => void;
    user: UserType | null;
}


// Create a context with default undefined value
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Create a provider component
export const AuthProvider: React.FC<any> = ({ children }) => {
    const [authToken, setAuthToken] = useState<string>(null);
    const isAuthenticated = useMemo(() => authToken != null, [authToken]);
    const [isPending, setIsPending] = useState(true);
    const [exchange, setExchange] = useState<ExchangeType>(null);
    const [user, setUser] = useState<UserType | null>(null);

    useLayoutEffect(() => {
        Cookies.get('auth') && setAuthToken(Cookies.get('auth'));
        setIsPending(false);
    }, []);

    const login = useCallback((res: AuthResponse) => {
        setAuthToken(res.token);
        Cookies.set('auth', res.token, { expires: res.expires_after_d });

        fetch(
            `${API_URL}/user`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${res.token}`,
                },
            }
        ).then(response => {
            if (response.ok) {
                response.json().then((data: UserType) => {
                    setUser(data);
                });
            }
        })
    }, []);

    const logout = useCallback(() => {
        setAuthToken(null);
        Cookies.remove('auth');
    }, []);

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout, authToken, isPending, exchange, setExchange, user }}>
            {children}
        </AuthContext.Provider>
    );
};

// Create a hook to use the AuthContext, this is a common pattern and helps avoid prop drilling
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};