import React, {useCallback} from 'react';
import styles from './style.module.scss';
import {UpdateSingleFieldModalProps} from './index.d';
import Modal from "../Modal/index";
import {useModal} from "../../contexts/ModalContext";

const UpdateSingleFieldModal: React.FC<UpdateSingleFieldModalProps> = ({label, value, name, update, inputConfig}) => {

    const {pop} = useModal();

    const [isInvalid, setIsInvalid] = React.useState(false);

    const textRef = React.createRef<HTMLInputElement>();

    const onSubmit = useCallback(async (event?: React.FormEvent) => {
        event && event.preventDefault();
        const text = textRef.current?.value.trim();
        const data = {
            [name]: text === '' ? null : text
        }
        const res = await update(data);
        if (res.success) {
            pop();
        } else {
            setIsInvalid(true);
        }
    }, [name, textRef, update, pop]);

    const resetInvalid = useCallback(() => {
        if (isInvalid) setIsInvalid(false);
    }, [isInvalid]);

    return (
        <Modal>
            <form className={styles.container} onSubmit={onSubmit}>
                <input onInput={resetInvalid} className={`${styles.input} ${isInvalid ? styles.invalid : ''}`} ref={textRef} type="text" id="single-field-input" defaultValue={value} autoFocus={true} placeholder={label} {...inputConfig} />
            </form>
        </Modal>
    );
}

export default UpdateSingleFieldModal;
