import React, {useCallback, useEffect, useRef, useState} from 'react';
import styles from './style.module.scss';
import {useNavigate} from 'react-router-dom';
import {useAuth} from "../../contexts/AuthContext";
import AuthLayout from "../../layouts/AuthLayout";
import {API_URL} from "../../config";

const AuthExchange = () => {

    const codeRef = useRef(null);
    const [isPending, setIsPending] = useState<boolean>(false);
    const [isInvalid, setIsInvalid] = useState<boolean>(false);

    const navigate = useNavigate();
    const {isAuthenticated, login, setExchange, exchange} = useAuth();

    useEffect(() => {
        if (!exchange) navigate('/');
    }, [exchange]);

    useEffect(() => {
        if (isAuthenticated) navigate('/chat');
    }, [isAuthenticated]);

    const submit = useCallback(async (event?) => {
        event?.preventDefault();
        if  (isPending) return;

        setIsPending(true);
        setIsInvalid(false)

        const response = await fetch(`${API_URL}/auth/exchange`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ code: codeRef.current.value, user_secret: exchange.userSecret}),
        });

        if (response.ok) {
            login(await response.json());
            setExchange(null);
            navigate('/chat');
        } else {
            if (response.status === 400) {
                setIsInvalid(true); // Invalid or expired code
            } else {
                console.error('Auth error', response.status);
            }
        }

        setIsPending(false);
    }, [isPending, exchange?.userSecret, login, setExchange, navigate]);

    const resetIsInvalid = useCallback(() => {
        setIsInvalid(false);
    }, []);

    return (
        <AuthLayout>
            <form className={styles.container} onSubmit={submit}>
                <h1 className={styles.title}>Zadajte kód</h1>
                <div className={styles.lead}>Zadajte 4-ciferný kód, ktorý sme odoslali na <i>{exchange?.email}</i>. Kód je platný 15 minút.</div>
                <div className={styles.inputContainer}>
                    <input className={styles.formInput}
                           aria-invalid={isInvalid}
                           type={"text"}
                           placeholder={"Kód"}
                           inputMode="numeric"
                           name={"code"}
                           autoComplete={"one-time-code"}
                           ref={codeRef}
                           maxLength={4}
                           pattern="\d{4}"
                           required={true}
                           onClick={resetIsInvalid}
                    />
                </div>
                <button className={`button ${styles.button}`} type={"submit"} disabled={isPending}>Pokračovať</button>
            </form>
        </AuthLayout>
    );
}

export default AuthExchange;
