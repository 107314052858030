import {useState} from "react";

const useForm = (defaultValues) => {

    const [values, setValues] = useState(defaultValues);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    return {
        values,
        handleChange
    };
}

export default useForm;