import {useCallback, useEffect, useRef, useState} from "react";
import {Status} from "../types/enums";
import {useAuth} from "../contexts/AuthContext";
import {MetaPagination} from "../types/global";

const useIndex = <T extends unknown>(endpoint: string, perPage = 5) => {

    const [data, setData] = useState<T[]>([]);
    const [status, setStatus] = useState(Status.NotLoaded);
    const [meta, setMeta] = useState<MetaPagination>({current_page: 1, last_page: 0, total: 0, per_page: perPage});

    const [etag, setEtag] = useState<string | null>(null);
    const abortController = useRef(new AbortController());

    const {authToken, logout} = useAuth();

    const fetchData = useCallback(async (urlParams = {}) => {

        setStatus(Status.Pending);

        abortController.current.abort();
        abortController.current = new AbortController();

        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        };

        if (etag) headers['If-None-Match'] = `${etag}`;

        try {

            const params = new URLSearchParams({
                page: meta.current_page.toString(),
                per_page: meta.per_page.toString()
            });

            Object.entries(urlParams).forEach(([key, value]) => {
                params.set(key, value.toString());
            });

            const url = `${endpoint}?${params.toString()}`;

            const response = await fetch(
                url,
                {
                    method: 'GET',
                    headers,
                    signal: abortController.current.signal
                }
            );

            if (response.ok) {
                // check if response is not modified
                if (response.status === 304 || (response.headers.get('etag') && response.headers.get('etag') === etag)) {
                    setStatus(Status.Complete);
                    return;
                }

                // update etag
                if (response.headers.get('etag')) setEtag(response.headers.get('etag'));

                // parse response
                const data = await response.json();
                setData(data.data);
                setMeta(data.meta);
                setStatus(Status.Complete);
            } else {
                if (response.status === 401) {
                    logout();
                }
                setStatus(Status.Failed);
                console.error(response.status, response.statusText)
            }

        } catch (e) {
            if (e && e.name === 'AbortError') {
                // setStatus(Status.Complete);
                console.log('Fetch aborted');
                return;
            }

            console.error(e);
            setStatus(Status.Failed);
        }

    }, [authToken, endpoint, etag, logout, meta]);

    useEffect(() => {
        fetchData();
    }, [meta.current_page]);

    const setPage = useCallback((pg: number) => {
        if (pg < 1 || pg > meta.last_page) return;
        setMeta({...meta, current_page: pg});
    }, [meta]);

    return {data, status, fetchData, meta, setPage};
}

export default useIndex;