import React, {useCallback, useEffect} from "react";

const useOpenAiStatus = () => {
    const [status, setStatus] = React.useState<'partial_outage' | 'major_outage' | 'operational' | 'investigating'>(null);

    const fetchStatus = useCallback(async () => {
        const res = await fetch('https://status.openai.com/api/v2/components.json', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if(res.ok) {
            const data = await res.json();
            const apiComponent = data.components.find((component) => component.name === 'API');
            if (apiComponent) {
                setStatus(apiComponent.status);
            }
        }
    }, []);

    useEffect(() => {
        fetchStatus();
    }, []);

    useEffect(() => {
        const interval = setInterval(fetchStatus, 120000);
        return () => clearInterval(interval);
    }, []);

    return {status};
}

export default useOpenAiStatus;