import React, {createContext, ReactNode, useCallback, useContext, useEffect, useState} from 'react';
import {ChatContextType} from "./ChatContext.d";
import {useDidUpdateEffect} from "../hooks/UseDidUpdateEffect";
import WebsocketEvent from "../utils/WebsocketEvent";
import {useWebsocket} from "./WebsocketContext";
import {UploadedFile} from "../types/global";
import {useAuth} from "./AuthContext";
import {useNavigate} from "react-router-dom";

const ChatContext = createContext<ChatContextType | undefined>(undefined);

export const ChatProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

    const {websocket} = useWebsocket();

    const [streamedMessage, setStreamedMessage] = useState<string>("");
    const [messages, setMessages] = useState<any[]>([]);
    const [isCompletionPending, setCompletionPending] = useState<boolean>(false);
    const [isHistoryLoaded, setIsHistoryLoaded] = useState(false);

    const {logout} = useAuth();

    const navigate = useNavigate();

    useDidUpdateEffect(() => {
        if (!websocket) return;

        const onCloseHandler = (event) => {
            setCompletionPending(false)
        }

        const onOpenHandler = (event) => {
            if (!isHistoryLoaded) {
                websocket.send(new WebsocketEvent({type: 'RequestHistory'}).dumps());
                setIsHistoryLoaded(true);
            }
        }

        websocket.addEventListener('close', onCloseHandler);
        websocket.addEventListener('open', onOpenHandler);

        return () => {
            websocket.removeEventListener('close', onCloseHandler);
            websocket.removeEventListener('open', onOpenHandler);
        }
    }, [websocket]);

    useDidUpdateEffect(() => {
        if (!isHistoryLoaded) return;

        const timeout = setTimeout(() => {
            if (messages.length > 0) return;
            websocket.send(new WebsocketEvent({type: 'OpenConversation'}).dumps());
        }, 2500);

        return () => {
            clearTimeout(timeout);
        }
    }, [isHistoryLoaded, messages]);


    const submit = useCallback((text: string, attachments: UploadedFile[] = []) => {
        if  (websocket.readyState !== WebSocket.OPEN){
            return false;
        }

        const files = attachments.filter((file) => file.purpose === 'assistants');
        const vision = attachments.filter((file) => file.purpose === 'vision');


        websocket.send(new WebsocketEvent({
            type: 'Message',
            data: {
                text: text,
                attachments: {
                    files: files.map((file) => file.file_id),
                    vision: vision.map((file) => file.file_id)
                }
            }
        }).dumps());

        const messagesToAppend = [];
        if (vision.length > 0) {
            vision.forEach((file: UploadedFile) => {
                messagesToAppend.push({type: 'image', data: {role: 'user', filename: file.filename}});
            });
        }
        messagesToAppend.push({type: 'message', data: {role: 'user', message: text}});

        setMessages(prevMessages => [...prevMessages, ...messagesToAppend]);

        return true;
    }, [websocket]);

    useEffect(() => {
        if (!websocket) {
            return;
        }

        let pendingStreamedMessage = streamedMessage; // TODO: refactor

        const messageHandler = (event: MessageEvent) => {
            const wsEvent = WebsocketEvent.loads(event.data);


            const cutStreamedMessage = () => {
                let textMessage = undefined;
                if (pendingStreamedMessage !== "") {
                    textMessage = {type: 'message', data: { role: 'assistant', message: streamedMessage }};
                    setStreamedMessage("");
                    pendingStreamedMessage = "";
                }
                return textMessage;
            }

            switch (wsEvent.type) {
                case 'MessageStart':
                    setStreamedMessage("");
                    setCompletionPending(true);
                    break;
                case 'MessageDelta':
                    setStreamedMessage(prevMessage => prevMessage + wsEvent.data.text);
                    break;
                case 'MessageEnd':
                    // appendWithStreamedMessage();

                    const newMessage = {type: 'message', data: { role: 'assistant', message: streamedMessage }};
                    setMessages(prevMessages => [...prevMessages, newMessage]);
                    setStreamedMessage("");

                    /*
                    if (streamedMessage) {
                        messagesToAppend.push({type: 'message', data: { role: 'assistant', message: streamedMessage }});
                        setStreamedMessage("");
                    }*/

                    setCompletionPending(false);
                    break;
                case 'Component':
                    let cutTextMessageComponent = cutStreamedMessage()
                    const newComponentMessage = {type: 'component', data: wsEvent.data};
                    setMessages(prevMessages => cutTextMessageComponent ? [...prevMessages, cutTextMessageComponent, newComponentMessage] : [...prevMessages, newComponentMessage]);

                    /*if (streamedMessage) {
                        messagesToAppend.push({type: 'message', data: { role: 'assistant', message: streamedMessage }});
                        setStreamedMessage("");
                    }
                    messagesToAppend.push({type: 'component', data: wsEvent.data});*/
                    break;
                case 'Image':
                    let cutTextMessageImage = cutStreamedMessage()
                    const newImageMessage = {type: 'image', data: { role: 'assistant', filename: wsEvent.data}};
                    setMessages(prevMessages => cutTextMessageImage ? [...prevMessages, cutTextMessageImage, newImageMessage] : [...prevMessages, newImageMessage]);
                    /*if (streamedMessage) {
                        messagesToAppend.push({type: 'message', data: { role: 'assistant', message: streamedMessage }});
                        setStreamedMessage("");
                    }

                    messagesToAppend.push({type: 'image', filename: wsEvent.data});*/
                    break;
                case 'Message':
                    // messagesToAppend.push({type: 'message', data: wsEvent.data});
                    setMessages(prevMessages => [...prevMessages, {type: 'message', data: wsEvent.data}]);
                    break;
                case 'MessageHistory':
                    setMessages(prevMessages => [{type: 'message', data: wsEvent.data}, ...prevMessages]);
                    break;
                case 'ImageHistory':
                    setMessages(prevMessages => [{type: 'image', data: wsEvent.data}, ...prevMessages]);
                    break;
                case 'Action':
                    const action = wsEvent.data;

                    switch (action.type) {
                        case 'logout':
                            logout();
                            navigate('/');
                            break;
                        case 'tool_call_finished':
                            const toolCallId = action.data?.id;
                            setMessages(prevMessages => prevMessages.map((message) => {
                                if (message.type === 'component' && message.data.type === 'tool_call' && message.data.data.id === toolCallId) {
                                    return {...message, data: {...message.data, data: {...message.data.data, isCompleted: true}}};
                                }
                                return message;
                            }));

                            /* setTimeout(() => {
                                setMessages(prevMessages => prevMessages.filter((message) => {
                                    return !(message.type === 'component' && message.data.type === 'tool_call' && message.data.data.id === toolCallId);

                                }));
                            }, 300);*/
                            break;
                        case 'tool_call_error':
                            const toolCallErrorId = action.data?.id;
                            setMessages(prevMessages => prevMessages.map((message) => {
                                if (message.type === 'component' && message.data.type === 'tool_call' && message.data.data.id === toolCallErrorId) {
                                    return {...message, data: {...message.data, data: {...message.data.data, isError: true, errorMessage: action.data.message}}};
                                }
                                return message;
                            }));
                            break;
                        default:
                            console.log('Unhandled action type:', action.type);
                    }

                    break;
                default:
                    // console.log('Unhandled message type:', wsEvent.type);
            }


        };

        websocket.addEventListener('message', messageHandler);

        return () => {
            websocket.removeEventListener('message', messageHandler);
        };
    }, [websocket, streamedMessage, logout, navigate]);

    return (
        <ChatContext.Provider value={{isCompletionPending, messages, streamedMessage, submit}}>
            {children}
        </ChatContext.Provider>
    );
};

export const useChat = () => {
    const context = useContext(ChatContext);
    if (context === undefined) {
        throw new Error('useChat must be used within an ChatContext');
    }
    return context;
};