import {ArrowPathIcon} from "@heroicons/react/20/solid";
import {Button} from "@headlessui/react";
import React from "react";

const RefreshButton = ({onClick, isPending = false}: {onClick: () => void, isPending: boolean}) => {

    return (
    <Button
        className={"text-gray-600 hover:text-gray-800 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"}
        title={"Obnoviť"}
        disabled={isPending}
        onClick={onClick}
    >
        <ArrowPathIcon className={`w-5 h-5 ${isPending ? 'animate-spin' : ''}`} />
    </Button>
    )
}

export default RefreshButton;