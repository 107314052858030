import React from 'react';
import styles from './style.module.scss';
import {ValueContainerProps} from './index.d';

const ValueContainer: React.FC<ValueContainerProps> = ({label, value, onClick}) => {

    return (
        <div className={`${styles.container} ${onClick ? styles.pressable : ''}`} role={onClick ? 'button' : undefined} onClick={onClick}>
            <div className={styles.label}>{label}</div>
            { value && <div className={styles.value}>{value}</div> }
        </div>
    );
}

export default ValueContainer;
