import {useEffect, useMemo} from 'react';
import {Disclosure, DisclosureButton, DisclosurePanel} from '@headlessui/react'
import {Bars3Icon, ChatBubbleLeftIcon, XMarkIcon} from '@heroicons/react/24/outline'
import ActivityIndicator from "../../components/ActivityIndicator";
import {Link, useLocation} from 'react-router-dom';
import {ReactComponent as LogoIcon} from "../../icons/logo-icon.svg";
import Chat from "../../components/Chat";
import {Avatar} from "flowbite-react";
import {useOpenAi} from "../../contexts/OpenAiContext";
import {resetReplyTo, setReplyTo} from "../../slices/ReplyToSlice";
import {useDispatch, useSelector} from "react-redux";
import {useAuth} from "../../contexts/AuthContext";
import {toggleChat} from "../../slices/ChatSlice";
import PopupMessage from "../../components/PopupMessage/index";

// bg-[#1D1128]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Dashboard = ({heading = undefined, isPending = false, chatContext = undefined, hideChat = false, fullScreen = false, headerButton = undefined, children}) => {

    const dispatch = useDispatch();

    const location = useLocation();
    const isChatOpen = useSelector((state) => state.chat.isOpen);
    const {status} = useOpenAi();
    const {user} = useAuth();

    const navigation = useMemo(() => (!user || user.role !== 'lead') ? [
        {
            name: 'Objednávky',
            href: '/order',
            current: location.pathname === '/order' || location.pathname === '/dashboard'
        },
        {name: 'Leady', href: '/lead', current: location.pathname === '/lead'},
        {name: 'Reporty', href: '/reports', current: location.pathname === '/reports'},
    ] : [], [location.pathname, user]);

    const statusType = useMemo(() => {
        switch (status) {
            case "investigating":
                return "away";
            case "major_outage":
                return "busy";
            case "partial_outage":
                return "away";
            case "operational":
                return "online";
            default:
                return "online";
        }
    }, [status]);

    useEffect(() => {
        if (chatContext) {
            dispatch(setReplyTo(chatContext));
        } else {
            dispatch(resetReplyTo());
        }
    }, [chatContext]);

    const handleToggleChat = () => dispatch(toggleChat());

    return (
        <div className={`min-h-screen bg-gray-100 flex flex-col ${fullScreen ? '' : 'pb-8'}`}>
            <Disclosure as="nav" className="bg-gray-900 border-b-gray-700 border-b sticky top-0 z-20">
                <div
                    className={`mx-auto px-4 sm:px-6 lg:px-8 transition-all duration-200 ease-in-out ${(isChatOpen && !hideChat) ? '' : 'max-w-7xl'}`}>
                    <div className="flex h-16 items-center justify-between">
                        <div className="flex items-center">
                            <Link
                                to="/"
                                className="text-white no-underline"
                            >
                                <div className="flex-shrink-0 text-gray-300 hover:text-white">
                                    <LogoIcon className="h-8 w-8"/>
                                </div>
                            </Link>
                            <div className="hidden md:block">
                                <div className="ml-10 flex items-baseline space-x-4">
                                    {navigation.map((item) => (
                                        <Link
                                            key={item.name}
                                            to={item.href}
                                            aria-current={item.current ? 'page' : undefined}
                                            className={classNames(
                                                item.current ? 'bg-gray-800 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white no-underline',
                                                'rounded-md px-3 py-2 text-sm font-medium no-underline',
                                            )}
                                        >
                                            {item.name}
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="-mr-2 flex md:hidden">
                            {/* Mobile menu button */}
                            <DisclosureButton
                                className="group relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                <span className="absolute -inset-0.5"/>
                                <span className="sr-only">Open main menu</span>
                                <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden"/>
                                <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block"/>
                            </DisclosureButton>
                        </div>
                    </div>
                </div>

                <DisclosurePanel className="md:hidden">
                    <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                        {navigation.map((item) => (
                            <DisclosureButton
                                key={item.name}
                                as="a"
                                href={item.href}
                                aria-current={item.current ? 'page' : undefined}
                                className={classNames(
                                    item.current ? 'bg-gray-800 text-white no-underline' : 'text-gray-300 hover:bg-gray-700 hover:text-white no-underline',
                                    'block rounded-md px-3 py-2 text-base font-medium',
                                )}
                            >
                                {item.name}
                            </DisclosureButton>
                        ))}
                    </div>
                </DisclosurePanel>
            </Disclosure>

            <div className={`flex transition-all duration-200 ease-in-out ${(isChatOpen && !hideChat) ? 'mr-[40%] lg:mr-[30%]' : ''}`}>
                <div
                    className={`flex-grow mx-auto transition-all duration-200 ease-in-out ${isChatOpen ? 'w-[70%] lg:w-[70%]' : 'w-full'} ${fullScreen ? 'max-w-full' : 'max-w-7xl px-4 lg:px-8 sm:px-6 '} `}>
                    {heading && <header>
                         <div className="pt-6 flex justify-between items-center gap-3">
                            <h1 className="text-3xl font-bold tracking-tight text-gray-900">{heading}</h1>
                             {headerButton && headerButton}
                        </div>
                    </header> }
                    <main className="min-h-100">
                        <div className={!fullScreen && "pt-6"}>
                            {isPending ? <ActivityIndicator color="#2563eb"/> : children}
                        </div>
                    </main>
                </div>

                {!hideChat && (<>
                    {/* Chat button and container */}
                    <div
                        className={`fixed bottom-4 right-4 z-50  ${isChatOpen ? 'w-[40%] lg:w-[30%] h-[94%] top-[4%] bottom-[2%] right-0' : ''}`}>
                        {!isChatOpen && (
                            <button
                                onClick={handleToggleChat}
                                className="bg-blue-500 transition-colors hover:bg-blue-600 text-white rounded-full p-4 shadow-lg flex flex-row gap-2 font-medium items-center"
                            >
                                <ChatBubbleLeftIcon className="h-7 w-7"/>
                            </button>
                        )}
                        {isChatOpen && (
                            <div className={`bg-gray-900 h-full shadow-lg flex flex-col border border-gray-800 rounded-xl px-3`}>
                                <div className={`p-4 flex justify-between items-center`}>
                                    <Avatar placeholderInitials="P" size={"sm"} rounded status={statusType}
                                            statusPosition="bottom-right"/>
                                    <h2 className="text-lg font-semibold text-gray-100 mr-2">Panda</h2>
                                    <button
                                        onClick={handleToggleChat}
                                        className="text-gray-300 hover:text-gray-100"
                                    >
                                        <XMarkIcon className="h-6 w-6"/>
                                    </button>
                                </div>
                                <div className="flex-grow overflow-y-auto">
                                    <Chat/>
                                </div>
                            </div>
                        )}
                    </div>
                </>)}
            </div>

            {!hideChat && (<>
                {/* Mobile Chat Overlay */}
                {isChatOpen && (
                    <div className="md:hidden fixed inset-0 bg-gray-900 z-50 flex flex-col">
                        <div className="p-4 flex justify-between items-center">
                            <Avatar placeholderInitials="P" size={"sm"} rounded status={statusType}
                                    statusPosition="bottom-right"/>
                            <h2 className="text-lg font-semibold text-gray-100 mr-2">Panda</h2>
                            <button
                                onClick={handleToggleChat}
                                className="text-gray-300 hover:text-gray-100"
                            >
                                <XMarkIcon className="h-6 w-6"/>
                            </button>
                        </div>
                        <div className="flex-grow overflow-y-auto">
                            <Chat/>
                        </div>
                    </div>
                )}
            </>)}

            <PopupMessage />
        </div>

    );
}

export default Dashboard;
