export const field = (value) => {
    return value ? value : '–';
}

export const getSourceColor = (source) => {
    switch (source) {
        case 'direct':
            return 'blue';
        case 'eshop':
            return 'yellow';
        case 'pharmacy':
            return 'green';
        case 'distributor':
            return 'purple';
        default:
            return 'gray';
    }
}

export const getSourceText = (source) => {
    switch (source) {
        case 'direct':
            return 'Priama';
        case 'eshop':
            return 'E-shop';
        case 'pharmacy':
            return 'Lekáreň';
        case 'distributor':
            return 'Distribútor';
        default:
            return '–';
    }
}