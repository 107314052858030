export const getStatusColor = (status) => {
    switch (status) {
        case 'pending':
            return 'gray';
        case 'completed':
            return 'green';
        case 'canceled':
            return 'red';
        default:
            return 'gray';
    }
}

export const getStatusText = (status) => {
    switch (status) {
        case 'pending':
            return 'Čakajúca';
        case 'completed':
            return 'Dokončená';
        case 'canceled':
            return 'Zrušená';
        default:
            return '–';
    }
}

export const getSource = (source) => {
    switch (source) {
        case 'direct':
            return 'Priama';
        case 'eshop':
            return 'E-shop';
        case 'pharmacy':
            return 'Lekáreň';
        case 'distributor':
            return 'Distribútor';
        default:
            return '–';
    }
}