import {configureStore} from '@reduxjs/toolkit';
import replyToReducer from './slices/ReplyToSlice'
import modelEventReducer from './slices/ModelEventSlice'
import chatReducer from './slices/ChatSlice'


export const store = configureStore({
    reducer: {
        replyTo: replyToReducer,
        modelEvent: modelEventReducer,
        chat: chatReducer
    },
});