import Dashboard from "../../layouts/Dashboard";
import Badge from "../../components/Badge";
import {API_URL} from "../../config";
import {LeadType} from "../../types/global";
import useResource from "../../hooks/UseResource";
import {useNavigate, useParams} from "react-router-dom";
import {Price} from "../../utils/Price";
import {field, getSourceColor, getSourceText} from "../../utils/FormatingUtils";
import {useModelDelete, useModelUpdate} from "../../hooks/UseModelEvents";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Spinner} from "flowbite-react";


const LeadShow = () => {

    const {lead_id} = useParams<{ lead_id: string }>();

    const navigate = useNavigate();

    const endpoint = useMemo(() => `${API_URL}/lead/${lead_id}`, [lead_id]);
    const {
        data,
        setData,
        forceSetData,
        isPending,
        resourceEmpty,
        fetchResource,
        deleteStatus,
        updateResource,
        deleteAlert
    } = useResource<LeadType>(endpoint);

    // TODO: Add special offers

    const [chatContext, setChatContext] = useState(undefined);
    useEffect(() => {
        if (data) {
            setChatContext({
                displayAs: 'Lead ' + data.name,
                sendAs: 'Lead ' + data._id,
            })
        }
    }, [data]);

    useModelUpdate('Lead', lead_id, fetchResource);
    useModelDelete('Lead', lead_id, useCallback(() => {
        navigate('/lead');
    }, [navigate]));

    return (<Dashboard heading={"Lead"} chatContext={chatContext}>
        {
            data ?
                <div className="border-t border-gray-200">
                    <dl className="divide-y divide-gray-200">
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Kontaktné údaje</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>Meno</div>
                                    <div>{field(data.name)}</div>
                                </div>
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>Email</div>
                                    <div>{field(data.email)}</div>
                                </div>
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>Telefón</div>
                                    <div>{field(data.phone)}</div>
                                </div>
                            </dd>
                        </div>

                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Spoločnosť</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>Obchodné meno</div>
                                    <div>{field(data.business_name)}</div>
                                </div>
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>Fakturačná adresa</div>
                                    <div>{field(data.billing_address)}</div>
                                </div>
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>Dodacia adresa</div>
                                    <div>{field(data.shipping_address)}</div>
                                </div>
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>IČO</div>
                                    <div>{field(data.cin)}</div>
                                </div>
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>DIČ</div>
                                    <div>{field(data.tin)}</div>
                                </div>
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>IČ DPH</div>
                                    <div>{field(data.vatin)}</div>
                                </div>
                            </dd>
                        </div>


                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Detaily</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>Zástupca</div>
                                    <div>{field(data.rep?.name)}</div>
                                </div>
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>Okres</div>
                                    <div>{field(data.county)}</div>
                                </div>
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>Typ</div>
                                    <div>{data.source ? <Badge
                                        color={getSourceColor(data.source)}>{getSourceText(data.source)}</Badge> : '–'}
                                    </div>
                                </div>
                            </dd>
                        </div>

                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Špecialne ceny</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                {
                                    (data.custom_prices && data.custom_prices.length) ? data.custom_prices.map((cp) => (
                                        <div className={"flex gap-3"} key={cp.product_name}>
                                            <div className={"font-medium"}>{cp.product_name}</div>
                                            <div>{Price.format(cp.price)}</div>
                                        </div>
                                    )) : '–'
                                }
                            </dd>
                        </div>

                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Poznámky</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                {
                                    (data.notes && data.notes.length) ? data.notes.map((note) => (
                                        <div className={"flex gap-3"} key={note._id}>
                                            {field(note.note)}
                                        </div>
                                    )) : '–'
                                }
                            </dd>
                        </div>

                    </dl>
                </div>
                :
                isPending && <div className={"flex justify-center"}><Spinner size="lg" className={"mt-6 mx-auto"}/></div>
        }
    </Dashboard>);
}

export default LeadShow;