import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    displayAs: null,
    sendAs: null
}

export const replyToSlice = createSlice({
    name: 'replyTo',
    initialState,
    reducers: {
        resetReplyTo: state => {
            state.displayAs = null;
            state.sendAs = null;
        },
        setReplyTo: (state, action) => {
            state.displayAs = action.payload.displayAs;
            state.sendAs = action.payload.sendAs;
        },
    },
});

export const { resetReplyTo, setReplyTo } = replyToSlice.actions;

export default replyToSlice.reducer;