// src/slices/ChatSlice.js
import {createSlice} from '@reduxjs/toolkit';

const chatSlice = createSlice({
    name: 'chat',
    initialState: {
        isOpen: false,
    },
    reducers: {
        toggleChat: (state) => {
            state.isOpen = !state.isOpen;
        },
        openChat: (state) => {
            state.isOpen = true;
        },
        closeChat: (state) => {
            state.isOpen = false;
        },
    },
});

export const { toggleChat, openChat, closeChat } = chatSlice.actions;
export default chatSlice.reducer;