import React, {useCallback, useMemo} from 'react';
import styles from './style.module.scss';
import {PaginatorProps} from "./index.d";
import {ReactComponent as AngleLeft} from "../../icons/angle-small-left.svg";
import {ReactComponent as AngleRight} from "../../icons/angle-small-right.svg";

const Paginator: React.FC<PaginatorProps> = ({currentPage, lastPage, onNextPage, onPrevPage}) => {

    const currentPagePadded = useMemo(() => {
        return currentPage.toString().padStart(2, '0');
    }, [currentPage]);

    const lastPagePadded = useMemo(() => {
        return lastPage.toString().padStart(2, '0');
    }, [lastPage]);

    const onPressPrev = useCallback(() => {
        if (currentPage > 1) {
            onPrevPage && onPrevPage();
        }
    }, [currentPage, onPrevPage]);

    const onPressNext = useCallback(() => {
        if (currentPage < lastPage) {
            onNextPage && onNextPage();
        }
    }, [currentPage, lastPage, onNextPage]);

    const isPrevDisabled = useMemo(() => currentPage <= 1, [currentPage]);
    const isNextDisabled = useMemo(() => currentPage >= lastPage, [currentPage, lastPage]);

    return (
        <div className={styles.container}>
            <div className={`${styles.angle} ${isPrevDisabled ? styles.disabled : ''} `} onClick={onPressPrev} >
                <AngleLeft/>
            </div>
            <div className={styles.block}>
                {currentPagePadded}<span className={styles.muted}>/{lastPagePadded}</span>
            </div>
            <div className={`${styles.angle} ${isNextDisabled ? styles.disabled : ''} `} onClick={onPressNext}>
                <AngleRight/>
            </div>
        </div>
    );
}


export default Paginator;