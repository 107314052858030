import React, {useMemo} from 'react';
import {LeadContainerProps} from './index.d';
import styles from './style.module.scss';
import {ReactComponent as PhoneIcon} from "../../../../icons/phone.svg";

const LeadContainer: React.FC<LeadContainerProps> = ({onClick, data: {initials, name, email, phone, clinic_name}}) => {

    const description = useMemo(() => {
        return clinic_name ?? phone ?? email ?? 'Lead';
    }, [clinic_name, phone, email])

    return (
        <div className={`${styles.container}`} onClick={onClick}>
            { initials && <div className={`${styles.initials}`}>
                {initials}
            </div> }
            <div className={styles.details}>
                <div className={styles.name}>
                    {name ?? 'Nový lead'}
                </div>
                { description && <div className={styles.description}>
                    {description}
                </div> }
            </div>
            {
            false && <button className={styles.action}>
                <PhoneIcon  />
            </button>
            }
        </div>
    );
}


export default LeadContainer;