import {useCallback, useState} from "react";

const useToggle = (initialState: boolean = false): {state: boolean, toggle: () => void} => {
    const [state, setState] = useState(initialState);

    const toggle = useCallback(() => {
        setState((prev) => !prev);
    }, []);

    return {state, toggle};
}

export default useToggle;