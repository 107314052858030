import React from 'react';
import {LeadComponentProps} from './index.d';
import styles from './style.module.css';
import {LeadType} from "../../types/global.d";
import ValueContainer from "../ValueContainer/index";
import useResource from "../../hooks/UseResource";
import {useModal} from "../../contexts/ModalContext";
import UpdateSingleFieldModal from "../UpdateSingleFieldModal/index";
import ReplyToButton from "../ReplyToButton/index";
import {useModelUpdate} from "../../hooks/UseModelEvents";

const LeadComponent: React.FC<LeadComponentProps> = ({id}) => {

    const {data, updateResource, forceSetData} = useResource<LeadType>(`http://127.0.0.1:5000/lead/${id}`);

    const {push} = useModal();

    useModelUpdate('Lead', id, forceSetData);

    return (
        <div className={styles.container}>
            {
                data && <>
                    <ValueContainer label={"Meno"} value={data.name} onClick={() => push(<UpdateSingleFieldModal label={"Meno"} value={data.name} name={"name"} update={updateResource} />)} />
                    <ValueContainer label={"Email"} value={data.email} onClick={() => push(<UpdateSingleFieldModal label={"Email"} value={data.email} name={"email"} update={updateResource} />)}/>
                    <ValueContainer label={"Telefón"} value={data.phone} onClick={() => push(<UpdateSingleFieldModal label={"Telefón"} value={data.phone} name={"phone"} update={updateResource} />)}/>
                </>
            }
            <ReplyToButton displayAs={`[${id}] ${data?.name ?? 'Nový lead'}`} sendAs={`Lead ID ${id}`} />
        </div>
    );
}


export default LeadComponent;