import React, {useCallback} from 'react';
import styles from './style.module.scss';
import {AssistantErrorProps} from "./index.d";
import {ReactComponent as ErrorIcon} from "../../icons/exclamation.svg";
import {ReactComponent as IconUp} from "../../icons/caret-up.svg";
import {ReactComponent as IconDown} from "../../icons/caret-down.svg";

const AssistantError: React.FC<AssistantErrorProps> = ({label = "Asistent narazil na chybu.", message = "(Bez popisu chyby.)"}) => {

    const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

    const toggle = useCallback(() => setIsExpanded((s) => !s), [])

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.icon}>
                    <ErrorIcon/>
                </div>
                <div className={styles.label}>
                    {label}
                </div>
                <div className={styles.toggleIcon}>
                    <div role={"button"} className={styles.toggleIconLink} onClick={toggle}>
                        {isExpanded ? <IconUp /> : <IconDown />}
                    </div>
                </div>
            </div>
            {isExpanded && <div className={styles.message}>
                {message}
            </div> }
        </div>
    );
}


export default AssistantError;