import React from 'react';
import {PreviewOrderProps} from './index';
import styles from './style.module.css';

const PreviewOrder: React.FC<PreviewOrderProps> = ({order, total}) => {



    return (
        <div className={styles.container}>

            <div className={styles.orderContainer}>
                {
                    order.map((item, index) => {
                        return (
                            <div key={index} className={styles.item}>
                                <div className={styles.itemThumbnail}>
                                    <img src={"/images/products/peptys21_thumbnail.webp"} alt={item.product_id}/>
                                </div>
                                <div className={styles.itemHeader}>
                                    <div
                                        className={styles.itemName}>{item.product_id === 'peptys_21' ? "PEPTYS 21/1ml" : item.product_id}</div>
                                    <div className={styles.itemQuantity}>{item.quantity} x {item.price_per_item}€</div>
                                </div>
                                <div className={styles.itemPrice}>{item.quantity * item.price_per_item}€</div>
                            </div>
                        )
                    })
                }
            </div>

            <div className={styles.totalContainer}>
                <div className={styles.totalHeader}>Spolu</div>
                <div className={styles.totalPrice}>{total}€</div>
            </div>
            <div className={styles.buttonContainer}>
                <button className={styles.button}>Potvrdiť objednávku</button>
            </div>
        </div>
    );
}


export default PreviewOrder;