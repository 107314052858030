import React, {useCallback, useMemo} from 'react';
import {MessageProps} from './index';
import styles from './style.module.scss';
import {ReactComponent as TypingAnimation} from '../../../../icons/typing.svg';
import {API_URL} from "../../../../config";
import Markdown from "react-markdown";

const Message = ({message, role}: MessageProps) => {

    const replaceMarkdown = useCallback((text: string): string => {
        // Replace markdown bold
        text = text.replace(/\*\*(.*?)\*\*/g, (match, p1) => {
            if (p1.includes("**")) {
                // Handles nested or broken bold markers by not transforming them
                return match;
            }
            return `<strong>${p1}</strong>`;
        });


        // Replace markdown code
        text = text.replace(/```(.*?)```/gs, (match, p1) => {
            if (p1.includes("```")) {
                // Handles nested or broken code markers by not transforming them
                return match;
            }
            return `<code>${p1}</code>`;
        });

        // Replace markdown links
        text = text.replace(/\[([^\]]+)\]\(([^)]+)\)/g, (match, p1, p2) => {
            p2 = p2.replace('sandbox:/mnt/data/', `${API_URL}/annotation/`)
            return `<a href="${p2}" target="_blank" referrerpolicy="no-referrer">${p1}</a>`;
        });

        return text
    }, []);

    const adjustText = useCallback((text: string): string => {
        text = text.replace('sandbox:/mnt/data/', `${API_URL}/annotation/`)
        text = text.replace('![', '[')
        text = text.replace(/<quote>(?:[^<]+|<(?!\/quote>))*<\/quote>/g, '')

        return text;
    }, []);

    const messageLines = useMemo(() => message.split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {replaceMarkdown(line)}
            <br/>
        </React.Fragment>
    )), [message, replaceMarkdown]);

    /*
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {message}
            </ReactMarkdown>
     */

    return (
        <div className={`${styles.container} ${role === 'user' ? styles.user : styles.assistant}`}>
            <Markdown className={role === 'user' ? styles.preWrap : undefined}>{adjustText(message)}</Markdown>
        </div>
    );
}

export const MessageTyping = () => (
    <div className={`${styles.container} ${styles.assistant}`}>
        <TypingAnimation/>
    </div>
)

export default Message;