import {useCallback, useState} from "react";
import debounce from 'lodash/debounce';

const useRefreshButtonNum = () => {
    const [refreshButtonNum, setRefreshButtonNum] = useState(0);
    const incrementRefreshButtonNum = useCallback(
        debounce(() => {
            setRefreshButtonNum((prev) => prev + 1);
        }, 300),
        []
    );

    return {refreshButtonNum, incrementRefreshButtonNum};
}

export default useRefreshButtonNum;