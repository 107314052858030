import React, {useEffect, useMemo, useState} from 'react';
import styles from './style.module.scss';
import {FileComponentProps} from "./index";
import {ReactComponent as CrossIcon} from "../../../../../../icons/cross.svg";


const FileComponent = ({file, onDelete, isFileUploadPending}: FileComponentProps) => {

    const [imagePreview, setImagePreview] = useState<string | null>(null);

    const extension = useMemo(() => {
        return file.name.split('.').pop().toLowerCase();
    }, [file])

    useEffect(() => {
        if (['png', 'jpg', 'jpeg', 'gif'].includes(extension)) {
            const objectUrl = URL.createObjectURL(file);
            setImagePreview(objectUrl);
            return () => URL.revokeObjectURL(objectUrl); // Clean up the object URL
        }
    }, [extension, file]);

    return (
        <div className={styles.container} style={imagePreview && { backgroundImage: `url(${imagePreview})` }}>
            <button className={styles.deleteIcon} onClick={onDelete} disabled={isFileUploadPending} title={"Odstrániť súbor"}>
                <CrossIcon/>
            </button>
            { !imagePreview && <>
            <div className={styles.filename} title={file.name}>
                {file.name}
            </div>
            <div className={styles.extension}>
                {extension}
            </div>
            </> }
        </div>
    );
}


export default FileComponent;