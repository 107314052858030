import styles from './style.module.scss';
import PopupMessage from "../../components/PopupMessage/index";
import Chat from "../../components/Chat";
import Dashboard from "../../layouts/Dashboard";


const ChatPage = () => {

    return (
        <Dashboard hideChat={true} fullScreen={true}>
            <>
                <div className={`${styles.wrapper} bg-gray-900`}>
                    <Chat />
                </div>
                <PopupMessage />
            </>
        </Dashboard>
    );
}

export default ChatPage;
