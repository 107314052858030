import React, {useCallback, useState} from 'react';
import {Menu, Transition} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/20/solid';
import {getStatusColor, getStatusText} from "../../utils/OrderUtils";
import Badge from "../../components/Badge";
import {API_URL} from "../../config";
import {useAuth} from "../../contexts/AuthContext";
import {Spinner} from "flowbite-react";

const statuses = [
    'pending',
    'completed',
    'canceled',
]

const MarkAsButton = ({ orderId }) => {

    const [isPending, setIsPending] = useState(false);
    const {authToken} = useAuth();

    const onStatusChange = useCallback((status) => {
        setIsPending(true);
        // API call to change status
        fetch(`${API_URL}/order/${orderId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify({status})
        }).then((res) => {
            if (!res.ok) {
                console.error('Failed to change status');
            }

            // res.json().then((data) => {
            //     console.log(data);
            // });
        }).finally(() => {
            setIsPending(false);
        });
    }, [authToken, orderId]);

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className="bg-grey-200 font-medium py-1.5 px-3 rounded-lg border-2 border-gray-300 no-underline text-sm hover:bg-gray-50 focus:outline-none transition-all duration-200 flex gap-2 items-center ring-gray-300 ring-offset-1 hover:ring text-gray-900">
                    Označiť ako
                    {isPending ? (
                        <Spinner size="sm" />
                    ) : (
                        <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                    )}
                </Menu.Button>
            </div>

            <Transition
                as={React.Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        {statuses.map((status) => (
                            <Menu.Item key={status}>
                                {({ focus }) => (
                                    <button
                                        onClick={() => onStatusChange(status)}
                                        className={`${
                                            focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                        } block px-4 py-2 text-sm w-full text-left`}
                                    >
                                        <Badge color={getStatusColor(status)}>{getStatusText(status)}</Badge>
                                    </button>
                                )}
                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
};

export default MarkAsButton;