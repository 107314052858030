import React, {useMemo} from 'react';
import styles from './style.module.scss';
import {Link} from "react-router-dom";
import {ReactComponent as Logo} from "../../icons/logo.svg";

const AuthLayout = ({children}) => {
    const year = useMemo(() => new Date().getFullYear(), []);

    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <div className={styles.logo}>
                    <Link to={"/"}><Logo width={200} /></Link>
                </div>
                <div className={styles.childrenContainer}>
                    {children}
                </div>
                <div className={styles.copyright}>
                    {year} &copy; All rights reserved
                </div>
            </div>
            <div className={styles.background}/>
        </div>
    );
}

export default AuthLayout;
