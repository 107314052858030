import {LeadType} from "../../../../types/global";
import Badge from "../../../../components/Badge";
import {field, getSourceColor, getSourceText} from "../../../../utils/FormatingUtils";
import {Link} from 'react-router-dom';

const OrderTableRow = ({lead}: {lead: LeadType}) => {

    return (
        <tr>
            <td className={"font-semibold px-2 py-3"}>{field(lead.name)}</td>
            <td className={"px-2 py-3"}>{field(lead.business_name)}</td>
            <td className={"px-2 py-3"}>{field(lead.rep?.name)}</td>
            <td className={"px-2 py-3"}>{ lead.source ? <Badge color={getSourceColor(lead.source)}>{getSourceText(lead.source)}</Badge> : '–' }</td>
            <td className={"px-2 py-3"}>
                <Link
                    to={`/lead/${lead._id}`}
                    type="button"
                    className="bg-grey-200 text-blue-600 font-medium py-1.5 px-3 rounded-md border border-gray-300 no-underline text-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition-colors duration-200"
                >
                    {"Detaily"}
                </Link>
            </td>
        </tr>
    )
}

export default OrderTableRow;