import React from 'react';
import ReactApexChart from 'react-apexcharts';

interface ProductChartProps {
    products: {
        product_id: string;
        product_name: string;
        quantity: number;
        revenue_cent: number;
    }[];
}

const ProductChart: React.FC<ProductChartProps> = ({ products }) => {
    const options: ApexCharts.ApexOptions = {
        chart: {
            type: 'bar',
            height: 350,
            stacked: false,
            toolbar: {
                show: false
            },
            background: 'transparent'
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                borderRadius: 4,
                dataLabels: {
                    position: 'top',
                },
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return val.toString();
            },
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ["#4b5563"]  // Darker gray for better readability
            }
        },
        xaxis: {
            type: 'category',
            categories: products.map(p => p.product_name),
            labels: {
                style: {
                    colors: '#4b5563',
                    fontSize: '12px',
                },
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: '#4b5563',
                    fontSize: '12px',
                },
            },
        },
        grid: {
            borderColor: '#e5e7eb',
            yaxis: {
                lines: {
                    show: true,
                    color: '#f3f4f6',
                },
            },
            xaxis: {
                lines: {
                    show: false,
                },
            },
        },
        legend: {
            position: 'top',
            horizontalAlign: 'right',
            offsetY: 10,
            labels: {
                colors: '#4b5563',
            },
        },
        fill: {
            opacity: 1
        },
        colors: ['#60a5fa', '#34d399'],  // Lighter blue and green
        tooltip: {
            theme: 'light',
        },
    };

    const series = [
        {
            name: 'Počet',
            data: products.map(p => p.quantity)
        },
        {
            name: 'Predaj (€)',
            data: products.map(p => p.revenue_cent / 100)
        }
    ];

    return (
        <div className="bg-white bg-opacity-65 p-6 rounded-xl shadow">
            <h2 className="text-xl font-bold text-gray-900 mb-4">Podľa produktov</h2>
            <ReactApexChart
                options={options}
                series={series}
                type="bar"
                height={350}
            />
        </div>
    );
};

export default ProductChart;