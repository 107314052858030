import React from 'react';
import styles from './style.module.css';
import ActivityIndicator from "../ActivityIndicator";

const LoadingScreen = ({wrapperStyle = undefined}) => {

    return (
        <div className={styles.wrapper} style={wrapperStyle}>
            <ActivityIndicator size={60} color={"#C1FFF2"}/>
        </div>
    );
}

export default LoadingScreen;
