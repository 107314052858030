import React, {useCallback} from 'react';
import styles from './style.module.scss';
import Modal from "../Modal/index";
import {ReactComponent as PowerIcon} from "../../icons/power.svg";
import {ReactComponent as ResetIcon} from "../../icons/rotate-right.svg";
import {useAuth} from "../../contexts/AuthContext";
import {useNavigate} from "react-router-dom";
import {API_URL} from "../../config";
import {ReactComponent as DashboardIcon} from "../../icons/dashboard-panel.svg";


const MenuModal: React.FC<any> = () => {

    const navigate = useNavigate();
    const {authToken, logout: destroyAuth} = useAuth();

    const onPressLogout = useCallback(async () => {
        const res = await fetch(`${API_URL}/auth`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            },
        });

        if (res.ok) {
            destroyAuth();
            navigate('/');
        }
    }, [authToken, navigate, destroyAuth]);

    const onPressNewChat = useCallback(async () => {
        const res = await fetch(`${API_URL}/thread/new`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
            }
        });

        if (res.ok) {
            window.location.reload();
        }
    }, [authToken, navigate]);

    const onPressDashboard = useCallback(() => {
        navigate('/dashboard');
    }, [navigate]);

    return (
        <Modal>
            <div className={styles.container}>
                <MenuButton icon={<ResetIcon/>} onClick={onPressNewChat} label={"Nový chat"}/>
                <div className={styles.separator}/>
                <MenuButton icon={<DashboardIcon/>} onClick={onPressDashboard} label={"Dashboard"}/>
                <div className={styles.separator}/>
                <MenuButton icon={<PowerIcon/>} onClick={onPressLogout} label={"Odhlásiť sa"}/>
            </div>
        </Modal>
    );
}


const MenuButton = ({icon, onClick, label}) => {
    return (
        <div className={styles.menuItem} role={"button"} onClick={onClick}>
            <div className={styles.icon}>
                {icon}
            </div>
            <div className={styles.label}>
                {label}
            </div>
        </div>
    );
}

export default MenuModal;
