import React, {useCallback} from 'react';
import styles from './style.module.scss';
import {LeadsProps} from "./index.d";
import useIndex from "../../hooks/UseIndex";
import {LeadType} from "../../types/global";
import LeadContainer from "./components/LeadContainer";
import Paginator from "../Paginator/index";
import {NavigationProvider, useNavigation} from "../../contexts/NavigationContext";
import Navigator, {Route} from "../Navigator/index";
import LeadComponent from "../Lead/index";

const LeadsComponent: React.FC<LeadsProps> = () => {

    const {data, status, fetchData, meta, setPage} = useIndex<LeadType>('http://127.0.0.1:5000/lead');
    const {navigate} = useNavigation();

    const onPrevPage = useCallback(() => {
        setPage(meta.current_page - 1);
    }, [meta, setPage]);

    const onNextPage = useCallback(() => {
        setPage(meta.current_page + 1);
    }, [meta, setPage]);

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                Leads <span className={styles.count}>{meta.total}</span>
            </div>

            <div className={styles.leadsContainer}>
            {
                data.map((lead, index) => {
                    const onClick = () => navigate('LeadComponent', {id: lead._id});
                    return <LeadContainer key={lead._id} data={lead} onClick={onClick} />
                })
            }
            </div>

            <Paginator currentPage={meta.current_page} lastPage={meta.last_page} onPrevPage={onPrevPage} onNextPage={onNextPage} />
        </div>
    );
}


const Leads:  React.FC<LeadsProps> = () => {
    return (
        <NavigationProvider>
            <Navigator>
                <Route name={"LeadsComponent"} title={"Leady"} component={LeadsComponent} index />
                <Route name={"LeadComponent"} title={"Lead"} component={LeadComponent} />
             </Navigator>
        </NavigationProvider>
    )
}


export default Leads;