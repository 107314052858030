import {useAuth} from "../contexts/AuthContext";
import {useNavigate} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import {useDidUpdateEffect} from "./UseDidUpdateEffect";
import {WS_URL} from "../config";

const RECONNECT_INTERVAL = 2500;

const useWebsocketConnector = () => {

    const {authToken, logout} = useAuth();
    const navigate = useNavigate();

    const [websocket, setWebsocket] = useState(null);

    const connectWebsocket = useCallback(() => {
        if (websocket) {
            websocket.close();
        }
        const ws = new WebSocket(`${WS_URL}?token=${authToken}`);
        setWebsocket(ws);
    }, [authToken, websocket]);

    useDidUpdateEffect(() => {
        if (!websocket) return;

        const closeHandler = (event) => {
            console.log(`WebSocket closed: Code=${event.code}, Reason=${event.reason}`);
            if (event.code === 1008) {
                console.info('Authentication failed. Please check your credentials.');
                logout();
                navigate('/');
            } else {
                // Attempt reconnect
                setTimeout(() => {
                    connectWebsocket();
                }, RECONNECT_INTERVAL);
            }
        }

        websocket.addEventListener("close", closeHandler);

        return () => {
            websocket.removeEventListener("close", closeHandler);
        }
    }, [websocket, logout, navigate, connectWebsocket]);

    useEffect(() => {
        if (websocket) return;
        connectWebsocket();
    }, []);

    return {websocket};
}

export default useWebsocketConnector;