import React from 'react';
import styles from './style.module.css';

const ActivityIndicator = ({size = 60, color = "#C1FFF2"}) => {

    return (
        <div className={styles.profileMainLoader}>
            <div className={styles.loader} style={{width: size, height: size}}>
                <svg className={styles.circularLoader} viewBox="25 25 50 50">
                    <circle className={styles.loaderPath} cx="50" cy="50" r="20" fill="none" stroke={color} strokeWidth="3"/>
                </svg>
            </div>
        </div>
    );
}

export default ActivityIndicator;
