import React, {createContext, ReactNode, useContext} from 'react';
import useOpenAiStatus from "../hooks/UseOpenAiStatus";

// Create a context with default undefined value
const OpenAiContext = createContext<{ status: 'partial_outage' | 'major_outage' | 'operational' | 'investigating' } | undefined>(undefined);

// Create a provider component
export const OpenAiProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const {status} = useOpenAiStatus();

    return (
        <OpenAiContext.Provider value={{ status }}>
            {children}
        </OpenAiContext.Provider>
    );
};

// Create a hook to use the AuthContext, this is a common pattern and helps avoid prop drilling
export const useOpenAi = () => {
    const context = useContext(OpenAiContext);
    if (context === undefined) {
        throw new Error('useOpenAi must be used within an OpenAiContext');
    }
    return context;
};