import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    type: null,
    model: null,
    data: null
}

export const modelEventSlice = createSlice({
    name: 'modelEvents',
    initialState,
    reducers: {
        setModelEvent: (state, action) => {
            state.type = action.payload.type;
            state.model = action.payload.model;
            state.data = action.payload.data;
        },
    },
});

export const { setModelEvent } = modelEventSlice.actions;

export default modelEventSlice.reducer;