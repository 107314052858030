import React, {useCallback, useState} from 'react';
import {useAuth} from '../../contexts/AuthContext';
import Dashboard from "../../layouts/Dashboard";
import ComboboxRep from '../../components/Filters/ComboboxRep';
import ListboxSource from '../../components/Filters/ListboxSource';
import {API_URL} from '../../config';
import {CountyType, RepType} from '../../types/global';
import {Spinner} from "flowbite-react";
import {useNavigate} from "react-router-dom";
import {usePopupMessage} from "../../contexts/PopupMessageContext";
import useForm from "../../hooks/UseForm";
import ComboboxRegion from "../../components/Filters/ComboboxRegion";


const LeadCreate = () => {
    const {authToken} = useAuth();
    const navigate = useNavigate();
    const {push: pushMessage} = usePopupMessage();
    const [isPending, setIsPending] = useState(false);
    const {values, handleChange} = useForm({
        name: "",
        email: "",
        phone: "",
        business_name: "",
        billing_address: "",
        shipping_address: "",
        cin: "",
        tin: "",
        vatin: "",
        note: "",
    });

    const [invalid, setInvalid] = useState({products: false});

    const [rep, setRep] = useState<RepType | null>(null);
    const [region, setRegion] = useState<CountyType | null>(null);
    const [source, setSource] = useState<string>('direct');

    const submit = useCallback(async (e) => {
        e.preventDefault();

        // convert empty strings to null
        const fieldValues = Object.keys(values).reduce((acc, key) => {
            // trim values
            if (typeof values[key] === "string") {
                values[key] = values[key].trim();
            }

            return {
                ...acc,
                [key]: values[key] === "" ? null : values[key]
            }
        }, {});

        const payload = {
            ...fieldValues,
            rep_id: rep?._id || null,
            county: region,
            source: source
        }

        setIsPending(true);
        const response = await fetch(`${API_URL}/lead`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(payload)
        })
        setIsPending(false);

        const responseData = await response.json();

        if (response.ok) {
            navigate(`/lead/${responseData._id}`);
        } else {
            pushMessage(responseData.message);
        }

    }, [values, rep, region, source, authToken, pushMessage, navigate]);

    return (
        <Dashboard heading={"Nový lead"}>
            <div className="space-y-6 max-w-[30rem] mx-auto" >

                <div className={"flex flex-col gap-2"}>
                    <div className="block text-md font-medium leading-6 text-gray-900">Kontaktné údaje</div>
                    <div className="flex flex-col gap-2 bg-gray-50 p-4 rounded-lg shadow-sm border border-white">
                        <InputField
                            id="name"
                            name="name"
                            type="text"
                            placeholder="Meno"
                            label="Meno"
                            onChange={handleChange}
                        />
                        <InputField
                            id="email"
                            name="email"
                            type="email"
                            placeholder="lead@example.com"
                            label="Email"
                            onChange={handleChange}
                        />
                        <InputField
                            id="phone"
                            name="phone"
                            type="tel"
                            placeholder="+421 123 456 789"
                            label="Telefón"
                            onChange={handleChange}
                        />
                    </div>
                </div>


                <div className={"flex flex-col gap-2"}>
                    <div className="block text-md font-medium leading-6 text-gray-900">Spoločnosť</div>
                    <div className="flex flex-col gap-2 bg-gray-50 p-4 rounded-lg shadow-sm border border-white">
                        <InputField
                            id="business_name"
                            name="business_name"
                            type="text"
                            placeholder="Obchodné meno"
                            label="Obchodné meno"
                            onChange={handleChange}
                        />
                        <InputField
                            id="billing_address"
                            name="billing_address"
                            type="text"
                            placeholder="Fakturačná adresa"
                            label="Fakturačná adresa"
                            onChange={handleChange}
                        />
                        <InputField
                            id="shipping_address"
                            name="shipping_address"
                            type="text"
                            placeholder="Dodacia adresa"
                            label="Dodacia adresa"
                            onChange={handleChange}
                        />

                        <InputField
                            id="cin"
                            name="cin"
                            type="text"
                            placeholder="IČO"
                            label="IČO"
                            onChange={handleChange}
                        />
                        <InputField
                            id="tin"
                            name="tin"
                            type="text"
                            placeholder="DIČ"
                            label="DIČ"
                            onChange={handleChange}
                        />
                        <InputField
                            id="vatin"
                            name="vatin"
                            type="text"
                            placeholder="IČ DPH"
                            label="IČ DPH"
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className={"flex flex-col gap-2"}>
                    <div className="block text-md font-medium leading-6 text-gray-900">Detaily</div>
                    <div className="flex flex-col gap-2 bg-gray-50 p-4 rounded-lg shadow-sm border border-white">

                        <div className={"flex items-center justify-between"}>
                            <div className="text-sm font-medium text-gray-900">Okres</div>
                            <div>
                                <ComboboxRegion onChange={setRegion}/>
                            </div>
                        </div>


                        <div className={"flex items-center justify-between"}>
                            <div className="text-sm font-medium text-gray-900">Zástupca</div>
                            <div>
                                <ComboboxRep onChange={setRep}/>
                            </div>
                        </div>

                        <div className={"flex items-center justify-between"}>
                            <div className="text-sm font-medium text-gray-900">Kategória</div>
                            <div>
                                <ListboxSource onChange={setSource} defaultValue={source} hideNullOption={true}/>
                            </div>
                        </div>

                    </div>
                </div>

                <div className={"flex flex-col gap-2"}>
                    <div className="block text-md font-medium leading-6 text-gray-900">Poznámka</div>
                    <div className="flex flex-col gap-2 bg-gray-50 p-4 rounded-lg shadow-sm border border-white">

                                            <textarea
                                                id="note"
                                                name="note"
                                                placeholder={"Poznámky o klientovi"}
                                                rows={3}
                                                className="block w-full rounded-lg border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                defaultValue={''}
                                                onChange={handleChange}
                                            />

                    </div>
                </div>

                <button
                    className={"flex gap-2 items-center justify-center bg-blue-600 text-md mx-auto w-full shadow-sm text-white font-medium py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors"}
                    type={"submit"} onClick={submit}>
                    {isPending ? <Spinner size={"sm"}/> : "Vytvoriť lead"}
                </button>


            </div>
        </Dashboard>
    )
}

interface InputFieldProps {
    id: string;
    name: string;
    type: string;
    placeholder: string;
    label: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputField: React.FC<InputFieldProps> = ({id, name, type, placeholder, label, onChange}) => {
    return (
        <div className="flex flex-col gap-2">
            <label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900">
                {label}
            </label>
            <input
                id={id}
                name={name}
                type={type}
                placeholder={placeholder}
                onChange={onChange}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
        </div>
    );
};

export default LeadCreate;