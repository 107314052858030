import React, {createContext, ReactNode, useContext, useEffect} from 'react';
import {WebsocketContextType} from "./WebsocketContext.d";
import useWebsocketConnector from "../hooks/UseWebsocketConnector";
import {useDispatch} from "react-redux";
import WebsocketEvent from "../utils/WebsocketEvent";
import {setModelEvent} from "../slices/ModelEventSlice";

const WebsocketContext = createContext<WebsocketContextType | undefined>(undefined);

export const WebsocketProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

    const {websocket} = useWebsocketConnector();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!websocket) {
            return;
        }

        const messageHandler = (event: MessageEvent) => {
            const wsEvent = WebsocketEvent.loads(event.data);

            switch (wsEvent.type) {
                case 'OnCreate':
                case 'OnUpdate':
                case 'OnDelete':
                    dispatch(setModelEvent({type: wsEvent.type, model: wsEvent.data.model, data: wsEvent.data.data}));
                    break;
                default:
                    // console.log('Unhandled message type:', wsEvent.type);
            }
        };

        websocket.addEventListener('message', messageHandler);

        return () => {
            websocket.removeEventListener('message', messageHandler);
        };
    }, [websocket, dispatch]);

    return (
        <WebsocketContext.Provider value={{websocket}}>
            {children}
        </WebsocketContext.Provider>
    );
};

export const useWebsocket = () => {
    const context = useContext(WebsocketContext);
    if (context === undefined) {
        throw new Error('useWebsocket must be used within an WebsocketContext');
    }
    return context;
};