import React, {createContext, ReactNode, useCallback, useContext, useState} from 'react';
import {PopupMessageContextType} from "./PopupMessageContext.d";

const PopupMessageContext = createContext<PopupMessageContextType | undefined>(undefined);

export const PopupMessageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [messages, setMessages] = useState([]);

    const push = useCallback((text: string) => {
        setMessages(current => [...current, {text}]);
    }, []);

    const pop = useCallback(() => {
        setMessages(current => current.slice(0, current.length - 1));
    }, []);

    return (
        <PopupMessageContext.Provider value={{ messages, push, pop }}>
            {children}
        </PopupMessageContext.Provider>
    );
};

export const usePopupMessage = () => {
    const context = useContext(PopupMessageContext);
    if (context === undefined) {
        throw new Error('usePopupMessage must be used within an PopupMessageProvider');
    }
    return context;
};