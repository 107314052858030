import {useDidUpdateEffect} from "./UseDidUpdateEffect";

const useDebounce = (fn: () => void, delay = 300) => {
    useDidUpdateEffect(() => {
        const debounce = setTimeout(() => {
            fn();
        }, delay)

        return () => clearTimeout(debounce)
    }, [delay, fn])
}

export default useDebounce;