class WebsocketEvent<T = any> {
    type: string = null;
    data: T = null;
    timestamp: number = Date.now();
    metadata: any = {};

    constructor(obj?: {type: string, data?: any, timestamp?: number, metadata?: any}) {
        if (obj) {
            this.type = obj.type;
            this.data = obj.data;
            this.timestamp = obj.timestamp || this.timestamp;
            this.metadata = obj.metadata || this.metadata;
        }
    }

    dumps() {
        return JSON.stringify({type: this.type, data: this.data, timestamp: this.timestamp, metadata: this.metadata});
    }

    static loads(serialized: string) {
        const parsed = JSON.parse(serialized);
        return new WebsocketEvent(parsed);
    }
}

export default WebsocketEvent;