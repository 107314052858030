import React, {createContext, ReactNode, useCallback, useContext, useMemo, useState} from 'react';
import {NavigationContextType, NavigationRoute} from "./NavigationContext.d";

// Create a context with default undefined value
const NavigationContext = createContext<NavigationContextType | undefined>(undefined);

// Create a provider component
export const NavigationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [history, setHistory] = useState<NavigationRoute[]>([]);

    const navigate = useCallback((route: string, params?: object) => {
        const routeProps: NavigationRoute = { route, params };
        setHistory(prevHistory => [...prevHistory, routeProps]);
    }, []);

    const goBack = useCallback(() => {
        if (history.length < 2) return;
        setHistory(prevHistory => prevHistory.slice(0, -1));
    }, [history]);

    const canGoBack = useMemo(() => history.length > 1, [history]);

    const currentRoute = useMemo(() => history[history.length - 1] || null, [history]);

    return (
        <NavigationContext.Provider value={{ currentRoute, navigate, goBack, canGoBack }}>
            {children}
        </NavigationContext.Provider>
    );
};

// Create a hook to use the AuthContext, this is a common pattern and helps avoid prop drilling
export const useNavigation = () => {
    const context = useContext(NavigationContext);
    if (context === undefined) {
        throw new Error('useNavigation must be used within an NavigationContext');
    }
    return context;
};