import React, {useCallback, useMemo} from 'react';
import styles from './style.module.scss';
import {ToolCallProps} from "./index.d";
import {ReactComponent as ActionIcon} from "../../icons/settings.svg";
import {ReactComponent as IconUp} from "../../icons/caret-up.svg";
import {ReactComponent as IconDown} from "../../icons/caret-down.svg";
import {ReactComponent as CheckIcon} from "../../icons/check-circle.svg";
import {ReactComponent as ErrorIcon} from "../../icons/times-hexagon.svg";

const ToolCall: React.FC<ToolCallProps> = ({function_name = "(Bez názvu funkcie.)", function_arguments = "(Bez parametrov.)", isCompleted = false, isError = false, errorMessage = undefined}) => {

    const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

    const toggle = useCallback(() => setIsExpanded((s) => !s), []);

    /**
     * Available functions:
     * add_product
     * update_product
     * create_lead
     * update_lead
     * create_lead_note
     * delete_lead_note
     * show_lead_notes
     * update_lead_note
     * update_lead_special_offers
     * get_lead_invitation_url
     * update_rep
     * create_order
     * update_order
     * create_external_order
     * updates_external_order
     * add_user
     * update_user
     * get_reports
     * authenticate_telegram
     * update_profile
     * update_event_subscription
     * aggregate
     * delete
     * browse
     */

    /*
    const function_name_translated = useMemo(() => {
        switch (function_name) {
            case "add_product":
                return "pridať produkt";
            case "update_product":
                return "aktualizovať produkt";
            case "create_lead":
                return "vytvoriť lead";
            case "update_lead":
                return "aktualizovať lead";
            case "create_lead_note":
                return "vytvoriť lead poznámku";
            case "delete_lead_note":
                return "vymazať lead poznámku";
            case "show_lead_notes":
                return "zobraziť lead poznámky";
            case "update_lead_note":
                return "aktualizovať lead poznámku";
            case "update_lead_special_offers":
                return "aktualizovať špeciálne ponuky";
            case "get_lead_invitation_url":
                return "získať pozvánkový odkaz pre lead";
            case "update_rep":
                return "aktualizovať obchodného zástupcu";
            case "create_order":
                return "vytvoriť objednávku";
            case "update_order":
                return "aktualizovať objednávku";
            case "create_external_order":
                return "vytvoriť externú objednávku";
            case "updates_external_order":
                return "aktualizovať externú objednávku";
            case "add_user":
                return "pridať používateľa";
            case "update_user":
                return "aktualizovať používateľa";
            case "get_reports":
                return "získať reporty";
            case "authenticate_telegram":
                return "overiť Telegram";
            case "update_profile":
                return "aktualizovať profil";
            case "update_event_subscription":
                return "aktualizovať odber udalostí";
            case "aggregate":
                return "agregovať";
            case "delete":
                return "vymazať";
            case "browse":
                return "hľadať";
            default:
                return function_name;
        }
    }, [function_name]);
    */

    const label = useMemo(() => {
        switch (function_name) {
            case "add_product":
                return "Asistent pridáva produkt.";
            case "update_product":
                return "Asistent aktualizuje produkt.";
            case "create_lead":
                return "Asistent vytvára lead.";
            case "update_lead":
                return "Asistent aktualizuje lead.";
            case "create_lead_note":
                return "Asistent vytvára poznámku k leadu.";
            case "delete_lead_note":
                return "Asistent vymazáva lead poznámku.";
            case "show_lead_notes":
                return "Asistent prezerá poznámky k leadu.";
            case "update_lead_note":
                return "Asistent aktualizuje poznámku k leadu.";
            case "update_lead_special_offers":
                return "Asistent aktualizuje špeciálne ponuky.";
            case "get_lead_invitation_url":
                return "Asistent získava pozvánkový odkaz pre lead.";
            case "update_rep":
                return "Asistent aktualizuje obchodného zástupcu.";
            case "create_order":
                return "Asistent vytvára objednávku.";
            case "update_order":
                return "Asistent aktualizuje objednávku.";
            case "create_external_order":
                return "Asistent vytvára externú objednávku.";
            case "updates_external_order":
                return "Asistent aktualizuje externú objednávku.";
            case "add_user":
                return "Asistent pridáva používateľa.";
            case "update_user":
                return "Asistent aktualizuje používateľa.";
            case "get_reports":
                return "Asistent získava reporty.";
            case "authenticate_telegram":
                return "Asistent generuje kód pre Telegram.";
            case "update_profile":
                return "Asistent aktualizuje profil.";
            case "update_event_subscription":
                return "Asistent aktualizuje odber udalostí.";
            case "aggregate":
                return "Asistent agreguje dáta.";
            case "delete":
                return "Asistent vymazáva dáta.";
            case "browse":
                return "Asistent vyhľadáva dáta.";
            case "export_data":
                return "Asistent exportuje dáta.";
            default:
                return "Asistent vykonáva funkciu `" + function_name + "`.";
        }
    }, [function_name]);

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                {!isCompleted ? <div className={`${styles.icon} ${styles.spinAnim}`}>
                    <ActionIcon />
                </div> : <div className={styles.icon}>
                    {!isError ? <CheckIcon /> : <ErrorIcon />}
                </div>}
                <div className={styles.label}>
                    {label}
                </div>
                <div className={styles.toggleIcon}>
                    <div role={"button"} className={styles.toggleIconLink} onClick={toggle}>
                        {isExpanded ? <IconUp /> : <IconDown />}
                    </div>
                </div>
            </div>
            {isExpanded && <div className={styles.message}>
                <code>{function_arguments}</code>
                {errorMessage && <><br /><code>{errorMessage}</code></>}
            </div> }
        </div>
    );
}


export default ToolCall;