import {useSelector} from "react-redux";
import {useEffect, useMemo} from "react";

export const useModelUpdate = (model: string, id: string, fnc: (data: any) => void) => {
    // @ts-ignore
    const modelEvent = useSelector(state => state.modelEvent);

    useEffect(() => {
        if (modelEvent.type === 'OnUpdate' && modelEvent.model === model && modelEvent.data._id === id) {
            fnc(modelEvent.data);
        }
    }, [id, model, modelEvent]);
}


export const useModelDelete = (model: string, id: string, fnc: () => void) => {
    // @ts-ignore
    const modelEvent = useSelector(state => state.modelEvent);

    useEffect(() => {
        if (modelEvent.type === 'OnDelete' && modelEvent.model === model && modelEvent.data._id === id) {
            fnc();
        }
    }, [id, model, modelEvent]);
}


export const useModelCreate = (model: string, fnc: (data: any) => void) => {
    // @ts-ignore
    const modelEvent = useSelector(state => state.modelEvent);

    useEffect(() => {
        if (modelEvent.type === 'OnCreate' && modelEvent.model === model) {
            fnc(modelEvent.data);
        }
    }, [model, modelEvent]);
}

export const useModelEvent = (model: string, type: string | string[], fnc: (data: any) => void) => {
    const eventsToListen = useMemo(() => Array.isArray(type) ? type : [type], [type]);

    // @ts-ignore
    const modelEvent = useSelector(state => state.modelEvent);

    useEffect(() => {
        if (eventsToListen.includes(modelEvent.type) && modelEvent.model === model) {
            fnc(modelEvent.data);
        }
    }, [model, modelEvent, eventsToListen]);
}