import {useEffect, useRef} from "react";

export function useDidUpdateEffect(fn, deps) {
    const fncRef = useRef();
    fncRef.current = fn;
    const didMountRef = useRef(false);

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        } else {
            // @ts-ignore
            return fncRef.current();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}