import {useEffect, useRef} from 'react';
import isEqual from 'lodash/isEqual';

function useEffectOnChange(effect: () => void | (() => void), deps: any[]) {
    const prevDepsRef = useRef<any[]>(deps);

    useEffect(() => {
        if (!isEqual(prevDepsRef.current, deps)) {
            effect();
        }
        prevDepsRef.current = deps;
    }, [deps, effect]);
}

export default useEffectOnChange;