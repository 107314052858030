import React, {Fragment, useCallback} from "react";
import {Transition} from '@headlessui/react';
import ComboboxLead from "../../Filters/ComboboxLead";
import ListboxStatus from "../../Filters/ListboxStatus";
import DateRange from "../../Filters/DateRange";
import ListboxSource from "../../Filters/ListboxSource";
import ComboboxRep from "../../Filters/ComboboxRep";

const AvailableFilters = [
    {id: "daterange", Component: DateRange},
    {id: "lead", Component: ComboboxLead},
    {id: "rep", Component: ComboboxRep},
    {id: "status", Component: ListboxStatus},
    {id: "source", Component: ListboxSource},
]

const Filter = ({id, Component, onChange}) => {
    const handleChange = useCallback((value) => {
        onChange(id, value);
    }, [id, onChange])

    return (
        <div className={"flex flex-col"}>
            {<Component onChange={handleChange}/>}
        </div>
    )
}

const FiltersContainer = ({show = false, onChange, filters = AvailableFilters}) => {

    return (
        <Transition
            as={Fragment}
            show={show}
            enter="transform transition ease-in-out duration-170"
            enterFrom="-translate-y-5 opacity-0"
            enterTo="translate-y-0 opacity-100"
            leave="transform transition ease-in-out duration-130"
            leaveFrom="translate-y-0 opacity-100"
            leaveTo="-translate-y-5 opacity-0"
        >
            <div className={"flex flex-row gap-2 mt-4 pt-4 border-t flex-wrap"}>
                {
                    filters.map((filter) => (
                        <Filter key={filter.id} {...filter} onChange={onChange}/>
                    ))
                }
            </div>
        </Transition>
    )
}

export default FiltersContainer;