import React, {useState} from 'react';
import {useNavigation} from "../../contexts/NavigationContext";
import {useDidUpdateEffect} from "../../hooks/UseDidUpdateEffect";
import styles from './style.module.scss';
import {ReactComponent as AngleLeft} from "../../icons/angle-small-left.svg";

const Navigator: React.FC<any> = ({children}) => {

    const { currentRoute, navigate, goBack, canGoBack } = useNavigation();
    const [ActiveComponent, setActiveComponent] = useState(null);
    const [title, setTitle] = useState(null);

    useDidUpdateEffect(() => {
        if (!currentRoute) {
            // Set default active route based on 'index' prop if no current route is set
            React.Children.forEach(children, child => {
                if (child.props.index) {
                    navigate(child.props.name);
                }
            });
        }
    }, [children, currentRoute, navigate]);

    useDidUpdateEffect(() => {
        React.Children.forEach(children, child => {
            if (child.props.name === currentRoute?.route) {
                const Component = child.props.component;
                // ActiveComponent = <Component {...currentRoute?.params} />;
                setActiveComponent(<Component {...currentRoute?.params} />);
                setTitle(child.props.title ?? child.props.name);
            }
        });
    }, [children, currentRoute]);


    return <div className={styles.container}>
        { canGoBack && <div className={styles.header}>
            <button className={styles.backButton} onClick={goBack}>
                <AngleLeft />
            </button>
            <div className={styles.title}>
                {title}
            </div>
        </div> }
        <div className={styles.content}>
            {ActiveComponent}
        </div>
    </div>;
}

export default Navigator;

export const Route: React.FC<{ name: string, component: React.FC, title?: string, index?: boolean }> = ()  => {
    return null; // This component is just a placeholder for configuration, it does not render anything itself.
};