import React, {useCallback} from 'react';
import styles from './style.module.scss';
import {ModalProps} from './index.d';
import {useModal} from "../../contexts/ModalContext";
import {ReactComponent as AngleLeft} from "../../icons/angle-small-left.svg";

const Modal: React.FC<ModalProps> = ({children}) => {

    const {pop} = useModal();

    const preventPropagation = useCallback((event: React.MouseEvent) => {
        event.stopPropagation();
    }, []);

    return (
        <div className={styles.overlay} onClick={pop} >
            <div className={styles.wrapper}>
                {
                false && <div className={styles.header}>
                    <button className={styles.close} onClick={pop}><AngleLeft/></button>
                </div>
                }
                <div className={styles.container} onClick={preventPropagation}>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default Modal;
