import React, {useCallback, useEffect, useRef, useState} from 'react';
import styles from './style.module.scss';
import {useNavigate} from 'react-router-dom';
import {useAuth} from "../../contexts/AuthContext";
import AuthLayout from "../../layouts/AuthLayout";
import {API_URL} from "../../config";

const SignIn = () => {

    const emailRef = useRef(null);
    const [isPending, setIsPending] = useState<boolean>(false);
    const [isInvalid, setIsInvalid] = useState<boolean>(false);

    const navigate = useNavigate();
    const {isAuthenticated, setExchange} = useAuth();

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/chat');
        }
    }, [isAuthenticated, navigate]);

    const submit = useCallback(async (event?) => {
        event?.preventDefault();
        if  (isPending) return;

        setIsPending(true);
        setIsInvalid(false)
        const email = emailRef.current.value;

        const response = await fetch(`${API_URL}/auth/dispatch`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ email }),
        });

        if (response.ok) {
            const res = await response.json();
            setExchange({userSecret: res.data.user_secret, email});
            navigate('/auth/exchange');
        } else {
            if (response.status === 422 || response.status === 404) {
                // 422 - Validation error, 404 - User not found
                setIsInvalid(true);
            } else {
                console.error('Auth error', response.status);
            }
        }

        setIsPending(false);
    }, [isPending, setExchange, navigate]);

    const resetIsInvalid = useCallback(() => {
        setIsInvalid(false);
    }, []);

    return (
        <AuthLayout>
            <form className={styles.container} onSubmit={submit}>
                <h1 className={styles.title}>Vitajte naspäť</h1>
                <div className={styles.lead}>Zadajte svoj email na ktorý vám zašleme kód na prihlásenie.</div>
                <div className={styles.inputContainer}>
                    <input className={styles.formInput}
                           aria-invalid={isInvalid}
                           type={"email"}
                           placeholder={"Email"}
                           name={"email"}
                           ref={emailRef}
                           required={true}
                           onClick={resetIsInvalid}
                           autoComplete={"email"}
                    />
                </div>
                <button className={`button ${styles.button}`} type={"submit"} disabled={isPending}>Pokračovať</button>
            </form>
        </AuthLayout>
    );
}

export default SignIn;
