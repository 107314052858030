import {MagnifyingGlassIcon} from "@heroicons/react/20/solid";
import React, {useCallback} from "react";
import useDebounce from "../../../hooks/UseDebounce";
import useForm from "../../../hooks/UseForm";

const SearchBar = ({onChange}: {onChange: (query: string) => void}) => {

    const {values, handleChange} = useForm({query: ""})

    useDebounce(useCallback(() => {
        onChange(values.query)
    }, [values.query, onChange]))

    return (
        <div className="relative rounded-lg">
            <label htmlFor="query" className="block text-sm font-medium leading-6 text-gray-900 sr-only">
                Hľadať
            </label>
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="text-gray-500 sm:text-sm"><MagnifyingGlassIcon className="w-4 h-4"/></span>
            </div>
            <input
                id="query"
                name="query"
                onChange={handleChange}
                type="text"
                placeholder="Hľadať"
                className="block w-full rounded-lg border-0 py-2 pl-9 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
        </div>
    )
}

export default SearchBar;