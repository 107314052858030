import React from 'react';
import ChatPage from "./routes/Chat";
import SignIn from "./routes/SignIn";
import {BrowserRouter as Router, Navigate, Outlet, Route, Routes} from 'react-router-dom';
import {useAuth} from "./contexts/AuthContext";
import LoadingScreen from "./components/LoadingScreen";
import {ModalProvider} from "./contexts/ModalContext";
import Invitation from "./routes/Invitation";
import AuthExchange from "./routes/AuthExhange";
import {PopupMessageProvider} from "./contexts/PopupMessageContext";
import OrderIndex from "./routes/OrderIndex";
import OrderShow from "./routes/OrderShow";
import LeadIndex from "./routes/LeadIndex";
import LeadShow from "./routes/LeadShow";
import Reports from "./routes/Reports";
import OrderCreate from "./routes/OrderCreate";
import {WebsocketProvider} from "./contexts/WebsocketContext";
import {ChatProvider} from "./contexts/ChatContext";
import {OpenAiProvider} from "./contexts/OpenAiContext";
import LeadCreate from "./routes/LeadCreate";


const ProtectedRoutes = () => {
    const {isAuthenticated, isPending} = useAuth();

    if (isPending) {
        return <LoadingScreen />;
    }

    return (
        isAuthenticated ? <Outlet/> : <Navigate to="/"/>
    )
}

function App() {

    // TODO: Dashboard protected -> not for leads
    return (
        <Router>
            <PopupMessageProvider>
                <ModalProvider>
                    <Routes>
                        <Route element={<ProtectedRoutes/>}>
                            <Route
                                element={
                                    <WebsocketProvider>
                                        <ChatProvider>
                                            <OpenAiProvider>
                                                <Outlet />
                                            </OpenAiProvider>
                                        </ChatProvider>
                                    </WebsocketProvider>
                                }
                            >
                                    <Route path="/chat" element={<ChatPage/>}/>
                                    <Route path="/dashboard" element={<OrderIndex />}/>
                                    <Route path="/order/create" element={<OrderCreate />}/>
                                    <Route path="/order" element={<OrderIndex />}/>
                                    <Route path="/order/:order_id" element={<OrderShow />}/>
                                    <Route path="/lead/create" element={<LeadCreate />}/>
                                    <Route path="/lead" element={<LeadIndex />}/>
                                    <Route path="/lead/:lead_id" element={<LeadShow />}/>
                                    <Route path="/reports" element={<Reports />}/>
                                </Route>
                        </Route>

                        <Route path="/" element={<SignIn/>} />
                        <Route path="/auth/exchange" element={<AuthExchange/>} />

                        <Route path="/invitation" element={<Invitation/>}/>
                    </Routes>
                </ModalProvider>
            </PopupMessageProvider>
        </Router>
    );
}

export default App;
